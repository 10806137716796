import React from 'react';
import { Space } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { PapaImage } from '~/common/papa-image';
import { Heading, ItemGrid, ItemGridElement } from '~/components';

export function HomeUseful(): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const openCartProducts = () => jpmActions.pushLayer(
        'RIGHT',
        'PRODUCT_ARCHIVE',
    );

    return (
        <>
            <Heading>
                Полезное
            </Heading>
            <ItemGrid>
                <ItemGridElement
                    title={'Архив'}
                    icon={(
                        <img
                            src={PapaImage.USEFUL_CART}
                            width={96}
                            height={96}
                        />
                    )}
                    onClick={openCartProducts}
                />
            </ItemGrid>
            <Space h={'l'}/>
        </>
    );
}
