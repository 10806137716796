import React, { useState } from 'react';
import { Button, Space, Text } from 'just-core-ui';
import { SessionSelectors } from '~/common/session';
import { Loan } from '~/common/product';
import { ContainerPage, FooterEmpty, ModalHeader, RadioGroup, Wrapper } from '~/components';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { PapaImage } from '~/common/papa-image';

export type ProductStatementProps = {
    loan: Loan;
    totalPayment: boolean;
};

const periodList = [
    {
        key: 'FULL',
        title: 'За весь период',
    },
    {
        key: 'QUARTER',
        title: 'За 4 квартал',
    },
    {
        key: 'MONTH',
        title: 'За прошлый месяц',
    },
];

const formatList = [
    {
        key: 'PDF',
        title: 'PDF',
    },
    {
        key: 'TXT',
        title: 'TXT',
    },
];

const productMakePayment = (params) => Promise.resolve();

export function ProductStatement(props: ProductStatementProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const popLayer = () => jpmActions.popLayer();

    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [period, setPeriod] = useState(null);
    const [format, setFormat] = useState(null);

    const user = SessionSelectors.user();
    const { loan } = props;

    const makePayment = () => {
        setLoading(true);
        productMakePayment({
            productId: loan.key,
            type: props.totalPayment ? 'total' : 'partial',
        })
            .then(() => setCompleted(true))
            .catch(() => console.log('ERROR making payment'));
    };

    return (
        <ContainerPage>
            <Wrapper>
                {completed ? (
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src={PapaImage.MAILBOX}
                                width={110}
                                height={110}
                            />
                        </div>
                        <Space h={'l'}/>
                        <ModalHeader
                            title={(
                                <>
                                    Готово!
                                </>
                            )}
                            description={(
                                <>
                                    Счет фактура для оплаты ежемесячного платежа
                                    {' '}
                                    отправлена на почту {user.email}
                                </>
                            )}
                        />
                        <Space h={'3xl'}/>
                        <Button
                            onClick={popLayer}
                            size={'l'}
                            autoFill
                        >
                            Закрыть
                        </Button>
                    </>
                ) : (
                    <>
                        <ModalHeader
                            title={(
                                <>
                                    Заказать выписку
                                </>
                            )}
                            description={(
                                <>
                                    Выберите период и формат файла, мы сформируем выписку и пришлем ее в чат и на электронную почту.
                                </>
                            )}
                        />
                        <Text
                            weight={'medium'}
                        >
                            За какой период?
                        </Text>
                        <Space h={'s'}/>
                        <RadioGroup
                            currentKey={period}
                            setCurrentKey={setPeriod}
                            list={periodList}
                        />
                        <Space h={'s'}/>
                        <Text
                            weight={'medium'}
                        >
                            В каком формате?
                        </Text>
                        <Space h={'s'}/>
                        <RadioGroup
                            currentKey={format}
                            setCurrentKey={setFormat}
                            list={formatList}
                        />
                        <Space h={'3xl'}/>
                        <Button
                            onClick={makePayment}
                            size={'l'}
                            disabled={!period || !format || loading}
                            autoFill
                        >
                            Сформировать выписку
                        </Button>
                    </>
                )}
            </Wrapper>
            <FooterEmpty/>
        </ContainerPage>
    );
}
