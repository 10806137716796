import React from 'react';
import { Space, Text } from 'just-core-ui/components';
import { createUseStyles } from 'react-jss';
import { colorToTop, useTheme } from 'just-core-ui';

export type ProfileRowProps = {
    title: string;
    value?: string;
    action?: React.ReactNode;
};

const useStyles = createUseStyles({
    ProfileRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export function ProfileRow(props: ProfileRowProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles();

    return (
        <div className={styles.ProfileRow}>
            <div>
                <Space h={'s'}/>
                <Text
                    weight={'medium'}
                    height={'s'}
                >
                    {props.title}
                </Text>
                {Boolean(props.value) && (
                    <>
                        <Space h={'2xs'}/>
                        <Text color={colorToTop(theme, 5)}>
                            {props.value}
                        </Text>
                    </>
                )}
                <Space h={'s'}/>
            </div>
            {props.action && (
                <div>
                    {props.action}
                </div>
            )}
        </div>
    );
}
