import React from 'react';
import { Text } from 'just-core-ui';

export type TextModalDescriptionProps = {
    children: React.ReactNode;
};

export function TextModalDescription(props: TextModalDescriptionProps): JSX.Element {
    return (
        <Text
            size={'s'}
            height={'s'}
            align={'center'}
        >
            {props.children}
        </Text>
    );
}
