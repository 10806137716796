import React from 'react';
import { Space } from 'just-core-ui/components';
import { TextModalTitle } from './text-modal-title';
import { TextModalDescription } from './text-modal-description';
import { Wrapper } from './wrapper';

export type ModalHeaderProps = {
    title: React.ReactNode;
    description: React.ReactNode;
    message?: string;
};

export function ModalHeader(props: ModalHeaderProps): JSX.Element {
    return (
        <Wrapper>
            <Space h={'m'}/>
            <TextModalTitle>
                {props.title}
            </TextModalTitle>
            <Space h={'m'}/>
            {props.description &&
              <TextModalDescription>
                {props.description}
              </TextModalDescription>
            }
            <Space h={'m'}/>
            {props.message &&
              <TextModalDescription>
                {props.message}
              </TextModalDescription>
            }
            <Space h={'xl'}/>
        </Wrapper>
    );
}
