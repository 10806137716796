import { JustPageManagerLayer, JustPageManagerPage, JustPageManagerState } from './common';

export const pushLayerToState = (state: JustPageManagerState, layer: JustPageManagerLayer): JustPageManagerState => ({
    ...state,
    layers: [
        ...state.layers,
        layer,
    ],
});

export const popLayerFromState = (state: JustPageManagerState): JustPageManagerState => ({
    ...state,
    layers: state.layers.length > 1
        ? state.layers.slice(0, state.layers.length - 1)
        : state.layers,
});

export const pushPageToState = (state: JustPageManagerState, page: JustPageManagerPage): JustPageManagerState => {
    const { layers } = state;
    const lastLayer = layers[layers.length - 1];

    return {
        ...state,
        layers: [
            ...layers.slice(0, layers.length - 1),
            {
                ...lastLayer,
                pages: [
                    ...lastLayer.pages,
                    page,
                ],
            },
        ],
    };
};

export const popPageFromState = (state: JustPageManagerState): JustPageManagerState => {
    const { layers } = state;
    const lastLayer = layers[layers.length - 1];

    if (lastLayer.pages.length > 1) {
        return {
            ...state,
            layers: [
                ...layers.slice(0, layers.length - 1),
                {
                    ...lastLayer,
                    pages: lastLayer.pages.slice(0, lastLayer.pages.length - 1),
                },
            ],
        };
    }

    return popLayerFromState(state);
};

export const createPage = (
    key: JustPageManagerPage['key'],
    props: JustPageManagerPage['props'],
): JustPageManagerPage => ({
    key,
    props,
});

export const createLayer = (
    type: JustPageManagerLayer['type'],
    key: JustPageManagerPage['key'],
    props: JustPageManagerPage['props'],
): JustPageManagerLayer => ({
    type,
    pages: [createPage(key, props)],
});
