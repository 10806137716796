import { Notification } from '../common';

export class NotifyService {
    static readonly instance = new NotifyService();

    hasTitle(notification: Notification): boolean {
        return Boolean(this.getTitle(notification));
    }

    getTitle(notification: Notification): string {
        if (notification.title) {
            return notification.title;
        }

        if (notification.type === 'success') {
            return 'Успех';
        }

        if (notification.type === 'warning') {
            return 'Внимание';
        }

        if (notification.type === 'error') {
            return 'Ошибка';
        }

        return null;
    }
}
