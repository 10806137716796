import {ChangeEvent, useState} from 'react';
import {phoneCodeMask} from "~/utils";

export type UseInputModifier = (value: string, isInc?: boolean) => string;
export type UseInputValidator = (value: string) => string | null;

export type UseInputParams = {
    modifiers?: Array<UseInputModifier>;
    validators?: Array<UseInputValidator>;
};

export type UseInput = {
    value: string;
    validation: { isValid: boolean; errors: Array<string>; };
    setValue: (value: string) => void;
    onChange: (event: ChangeEvent) => void;
};

const validate = (value: string, validators?: Array<UseInputValidator>) => {
    const errors = validators ? validators.reduce((ers, vld) => {
        const err = vld(value);

        return err ? [...ers, err] : ers;
    }, []) : [];

    const isValid = !errors.length;

    return {
        isValid,
        errors,
    };
};

export const useInput = (defaultValue: string, params?: UseInputParams): UseInput => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (event) => {
        const newValue = params && params.modifiers
            ? params.modifiers.reduce((v: string, modifier: UseInputModifier) => modifier(v, value.length < v.length), event.target.value)
            : event.target.value;

        setValue(newValue);
    };

    return {
        value,
        validation: validate(value, params && params.validators),
        setValue,
        onChange,
    };
};

export const useInputModifierOnlyNumbers = () => (value: string) => String(value).replace(/\D/gi, '');

export const useInputModifierOnlyNumbersAndDash = () => (value: string) =>
    phoneCodeMask(value)
        .split("")
        .map(elem => (!isNaN(Number(elem)) || elem === "-") ? elem : "")
        .join("")

export const useInputModifierPhone = (code: number) => (value: string) => String(value).length && String(value)[0] !== String(code) ? `${code}${String(value)}` : String(value);

export const useInputModifierLimiter = (limit: number) => (value: string) => String(value).slice(0, limit);

export const useInputValidatorPhone = () => (value: string) => String(value).length < 11 ? 'Телефон должен быть не короче 11 символов' : null;

export const useInputValidatorMinLength = (length: number) => (value: string) => String(value).length < length ? `Значение не может быть короче ${length} символов` : null;
