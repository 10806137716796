import { JsonRpcClient } from '~/common/just-json-rpc-client';
import { AppScheduledPaymentGetListParams, AppScheduledPaymentGetListResult } from '~/common/app-api';

export class ScheduledPaymentRepository {
    static readonly instance = new ScheduledPaymentRepository();

    private jsonRpcClient: JsonRpcClient;

    constructor() {
        this.jsonRpcClient = new JsonRpcClient('/api', {
            addMethodToUrl: true,
        });
    }

    async appScheduledPaymentGetList(params: AppScheduledPaymentGetListParams): Promise<AppScheduledPaymentGetListResult> {
        return this.jsonRpcClient.call('app-scheduled-payment-get-list', params);
    }
}
