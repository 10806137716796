import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

export function useActions<T>(actions: T, deps: Array<any> = []): T {
    const dispatch = useDispatch();

    return useMemo(
        () => bindActionCreators<T, any>(actions, dispatch),
        deps ? [dispatch, ...deps] : [dispatch]
    )
}
