import React from 'react';
import { createUseStyles } from 'react-jss';
import { Space, Text } from 'just-core-ui/components';

export type RadioGroupPropsListItem = {
    key: string;
    title: React.ReactNode;
    description?: React.ReactNode;
    disabled?: boolean;
};

export type RadioGroupProps = {
    currentKey: string;
    setCurrentKey: (value: string) => void;
    list: Array<RadioGroupPropsListItem>;
};

const useStyles = createUseStyles({
    RadioGroup: {},
    RadioGroup__Item: {
        cursor: 'pointer',
    },
    RadioGroup__Radio: {
        float: 'left',
    },
    RadioGroup__Label: {
        marginLeft: 30,
    },
});

export function RadioGroup(props: RadioGroupProps): JSX.Element {
    const setCurrentKey = (value: string, disabled: boolean) => () => !disabled && props.setCurrentKey(value);

    const styles = useStyles();

    return (
        <div className={styles.RadioGroup}>
            {props.list.map(listItem => (
                <React.Fragment key={listItem.key}>
                    <Space h={'s'}/>
                    <div
                        className={styles.RadioGroup__Item}
                        style={{ cursor: listItem.disabled ? 'default' : null }}
                        onClick={setCurrentKey(listItem.key, listItem.disabled)}
                    >
                        <div className={styles.RadioGroup__Radio}>
                            <input
                                type={'radio'}
                                checked={props.currentKey === listItem.key}
                                disabled={listItem.disabled}
                                onChange={event => console.log('Нельзя checked без onChange', event)}
                            />
                        </div>
                        <div className={styles.RadioGroup__Label}>
                            <Text
                                size={'s'}
                                height={'l'}
                                weight={'medium'}
                                // colorStep={listItem.disabled ? 4 : void 0}
                            >
                                {listItem.title}
                            </Text>
                            {listItem.description && (
                                <>
                                    <Space h={'2xs'}/>
                                    <Text
                                        size={'s'}
                                        // colorStep={listItem.disabled ? 4 : 0}
                                    >
                                        {listItem.description}
                                    </Text>
                                </>
                            )}
                        </div>
                    </div>
                    <Space h={'s'}/>
                </React.Fragment>
            ))}
        </div>
    );
}
