import React from 'react';
import { Cell, Link, Space, Text } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { CalendarSvg20 } from '~/common/icons';
import { Money, Wrapper } from '~/components';
import { formatDate } from '~/utils';
import { Loan, ScheduledPayment, ScheduleSlice } from '../common';
import { ProductCard } from './product-card';
import { ProductCardRow } from './product-card-row';

type ProductCardPaymentScheduleProps = {
    scheduledPayments: Array<ScheduledPayment>;
    scheduleSlice: ScheduleSlice;
    loan: Loan;
    onClick?: () => void;
};

export function ProductCardPaymentSchedule(props: ProductCardPaymentScheduleProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();
    const message = null;

    const handleMoreClick = () => jpmActions.pushLayer(
        'MODAL',
        'PRODUCT_SCHEDULED_PAYMENTS',
        {
            scheduledPayments: props.scheduledPayments,
            loan: props.loan,
        },
    );

    return (
        <Wrapper>
            <ProductCard
                title={'График платежей'}
                icon={CalendarSvg20}
            >
                {Boolean(props.scheduleSlice.paymentsLeft) && (
                    <ProductCardRow
                        title={'Осталось платежей:'}
                        value={props.scheduleSlice.paymentsLeft}
                    />
                )}
                {Boolean(props.scheduleSlice.nextPaymentDate) && (
                    <ProductCardRow
                        title={'Следующий платеж:'}
                        value={formatDate(props.scheduleSlice.nextPaymentDate)}
                    />
                )}
                {Boolean(props.scheduleSlice.nextPaymentAmount) && (
                    <ProductCardRow
                        title={'Сумма:'}
                        value={<Money amount={props.scheduleSlice.nextPaymentAmount}/>}
                    />
                )}
                {Boolean(message) && (
                    <ProductCardRow
                        title={message}
                        value={''}
                    />
                )}
                {Boolean(!message) && (
                    <>
                        <Space h={'xs'}/>
                        <Cell
                            paddingY={'xs'}
                        >
                            <Text
                                size={'s'}
                                height={'s'}
                            >
                                <Link onClick={handleMoreClick}>
                                    Все платежи
                                </Link>
                            </Text>
                        </Cell>
                    </>
                )}
            </ProductCard>
        </Wrapper>
    );
}
