import React from 'react';
import { JustPageManagerContextValue } from '../common';

export const JustPageManagerContext = React.createContext<JustPageManagerContextValue>({
    state: {
        layers: [],
    },
    actions: {
        pushLayer: () => void 0,
        popLayer: () => void 0,
        pushPage: () => void 0,
        popPage: () => void 0,
    },
});
