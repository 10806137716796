import React, { useState } from 'react';
import { Button, Input, Space } from 'just-core-ui';
import { mapProductTypeToRadioItem, ProductActions, ProductType } from '~/common/product';
import { useInput } from '~/hooks/use-input';
import { SessionSelectors } from '~/common/session';
import { ContainerPage, FooterEmpty, ModalHeader, RadioGroup, Wrapper } from '~/components';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { PapaImage } from '~/common/papa-image';
import { useActions } from '~/hooks';

const productMakePayment = (params) => Promise.resolve();

export type ProductNewProps = {
    type?: ProductType;
};

export function ProductNew(props: ProductNewProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();
    const productActions = useActions(ProductActions);

    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [productType, setProductType] = useState(props.type);

    const loanAmount = useInput('');

    const customer = SessionSelectors.customer();

    const productTypeList = [
        ProductType.ACQUIRING_PREPAYMENT,
        ProductType.MARKETPLACE_LOAN,
        ProductType.ONLINE_STORE_LOAN,
        ProductType.TENDER_LOAN,
        ProductType.CONTRACT_LOAN,
        ProductType.CARGO_LOAN,
        ProductType.TURNOVER_LOAN,
        ProductType.FACTORING_LOAN,
        ProductType.TRANSPORT_LEASING,
        ProductType.EQUIPMENT_LEASING,
        ProductType.LOAN_UNDER_PTS,
        ProductType.FRANCHISE_LOAN,
    ].map(mapProductTypeToRadioItem);

    const makePayment = () => {
        setLoading(true);

        productActions.createLoanRequest(customer.id, String(productType), Number(loanAmount.value), loanRequest => {
            setCompleted(true);
        });
    };

    return (
        <ContainerPage>
            <Wrapper>
                {completed ? (
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src={PapaImage.ROCKET}
                                width={110}
                                height={110}
                            />
                        </div>
                        <Space h={'l'}/>
                        <ModalHeader
                            title={(
                                <>
                                    Заявка отправлена!
                                </>
                            )}
                            description={(
                                <>
                                    Мы все проверим и свяжемся с вами в самое ближайшее время.
                                </>
                            )}
                        />
                        <Space h={'3xl'}/>
                        <Button
                            onClick={jpmActions.popLayer}
                            size={'l'}
                            autoFill
                        >
                            Закрыть
                        </Button>
                    </>
                ) : (
                    <>
                        <ModalHeader
                            title={(
                                <>
                                    Новый займ
                                </>
                            )}
                            description={(
                                <>
                                    Выберите доступный для вас продукт, сумму займа и приложите выписку.
                                </>
                            )}
                        />
                        <Input
                            title={'Укажите сумму займа (руб.)'}
                            value={loanAmount.value}
                            onChange={loanAmount.onChange}
                        />
                        <RadioGroup
                            currentKey={String(productType)}
                            setCurrentKey={(pt: string) => setProductType(Number(pt))}
                            list={productTypeList}
                        />
                        <Space h={'m'}/>
                        <Button
                            onClick={makePayment}
                            size={'l'}
                            disabled={!loanAmount.value || typeof productType === 'undefined' || loading}
                            autoFill
                        >
                            Готово
                        </Button>
                    </>
                )}
            </Wrapper>
            <FooterEmpty/>
        </ContainerPage>
    );
}
