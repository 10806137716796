import React from 'react';

export type SvgIconProps = {
    size: number;
    children: React.ReactNode;
};

export function SvgIcon(props: SvgIconProps): JSX.Element {
    return (
        <div
            style={{
                display: 'flex',
                width: props.size,
                height: props.size,
            }}
        >
            {props.children}
        </div>
    );
}
