import React from 'react';

const DEFAULT_SYMBOL = '₽';
const DELIMITER = '&thinsp;';
const DELIMITER_CURRENCY = '&VeryThinSpace;';

type MoneyProps = {
    amount: string;
    forceDec?: boolean;
};

export function Money(props: MoneyProps): JSX.Element {
    const sides = props.amount.split('.');
    const right = sides[1] || '';
    const after = `${right[0] || '0'}${right[1] || '0'}`;
    const realAfter = Number(after) || props.forceDec ? `,${after}` : '';
    const before = (sides[0] || '').split('').reverse().join('');

    let amount = '';

    for (let i = 0; i < before.length; i++) {
        amount += before[i];

        if ((i + 1) % 3 === 0 && before.length - i > 1) {
            amount += '_';
        }
    }

    amount = amount.split('').reverse().join('') + realAfter + DELIMITER_CURRENCY + DEFAULT_SYMBOL;
    amount = amount.replace(/_/gi, DELIMITER);

    const dangerHtml = { __html: amount };

    return (
        <span dangerouslySetInnerHTML={dangerHtml}/>
    );
}
