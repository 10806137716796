import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorToTop, Space, StyleService, Text, Theme, useTheme } from 'just-core-ui';
import { Notification } from '../common';
import { NotifyService } from '../services';

export type NotifyNotificationProps = {
    notification: Notification;
};

const useStyles = createUseStyles({
    NotifyNotification: (theme: Theme) => ({
        padding: StyleService.instance.getSpace(theme, 'm'),
        borderRadius: StyleService.instance.getSpace(theme, 'm'),
        background: colorToTop(theme, { opacity: 0.9 }),
        width: 300,
        boxSizing: 'border-box',
        boxShadow: `0 0 ${StyleService.instance.getSpace(theme, 's')}px 0 ${colorToTop(theme, 1)}`,
    }),
});

export function NotifyNotification(props: NotifyNotificationProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles(theme);
    const { code } = props.notification;

    return (
      (code == '1005' || code == '1006' || code == '1007')
      &&
        <div className={styles.NotifyNotification}>
            {NotifyService.instance.hasTitle(props.notification) && (
                <>
                    <Text
                        weight={'medium'}
                        color={colorToTop(theme, 7)}
                    >
                        {NotifyService.instance.getTitle(props.notification)}
                    </Text>
                    <Space h={'xs'}/>
                </>
            )}
            <Text
                size={'s'}
                color={colorToTop(theme, 7)}
            >
                {props.notification.message}
            </Text>
        </div>
    );
}
