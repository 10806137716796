import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container } from 'just-core-ui';


type ContainerProps = {
    children?: React.ReactNode;
};

const useStyles = createUseStyles({
    Container: {
        // border: '10px solid tomato',
    },
});

export function ContainerPage(props: ContainerProps): JSX.Element {
    const styles = useStyles();

    return (
      <Container
        maxWidth={800}
        paddingX={'s'}
      >
        <div className={styles.Container}>
          {props.children}
        </div>
      </Container>
    );
}
