import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Link } from 'just-core-ui';
import { setSessionKey } from '~/common/session';
import { PapaImage } from '~/common/papa-image';

const phoneNumber: string = process.env.PHONE_NUMBER;
const phoneNumberHTML: string = process.env.PHONE_NUMBER_HTML;
const PFSiteLink: string = process.env.SITE_LINK;

const useStyles = createUseStyles({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    paddingBottom: '35px',
  },
  pageHeader__LogoLink: {
    display: 'block',
    height: '16px',
  },
  pageHeader__Link: {
    textDecoration: 'none',
  },
  pageHeader__Links: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
  },
  '@media (max-width: 450px)': {
    pageHeader__Link: {
      display: 'none',
    }
  }
});

export function MainHeader(): JSX.Element {
  const logout = () => {
    setSessionKey('');
    location.reload();
  };

  const styles = useStyles();

    return (
      <div className={styles.pageHeader}>
        <a className={styles.pageHeader__LogoLink} href={PFSiteLink} target="_blank">
            <img src={PapaImage.LOGO} alt="LOGO" />
        </a>
        <div className={styles.pageHeader__Links}>
          <a className={styles.pageHeader__Link} href={`tel:${phoneNumber}`}>
            <Link>{phoneNumberHTML}</Link>
          </a>
          <Button onClick={logout}>Выйти</Button>
        </div>
      </div>
    );
}
