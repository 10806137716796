import React from 'react';
import { SvgIcon } from '~/components';

export const CardIconSvg30 = ({ color, size = 30 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.05437 7.61933C11.2597 7.31202 14.1663 7.13141 15.7742 7.0775C20.8131 6.90855 23.3526 7.0669 23.8591 7.0775C24.9826 7.10102 25.8846 7.56274 26.1392 8.53644C26.3938 9.51015 27.2318 20.6118 25.4021 21.5195C24.3255 22.0535 15.2755 22.3718 9.2251 22.2398C7.20986 22.1958 5.12584 22.0192 4.81533 21.5195C3.65094 19.645 3.80835 9.28185 4.81533 8.01601" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.99426 15.8268L26.0042 15.6068" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.21426 18.3568C14.6124 18.5768 19.6203 18.6868 20.238 18.6868C20.8559 18.6868 22.8879 18.5768 26.3341 18.3568" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.53454 14.1768C6.52912 13.7102 6.59747 13.3791 6.73961 13.1834C6.95283 12.8899 7.36511 13.7199 7.51344 13.6801C7.66177 13.6404 7.76235 12.2852 8.01326 12.5648C8.26416 12.8445 8.20293 14.1768 8.45958 14.1768C8.71623 14.1768 8.42156 12.5809 9.26579 12.7702C10.11 12.9594 10.4976 14.0511 10.7529 14.0511C11.0081 14.0511 11.0128 13.0149 11.3104 13.1834C11.6079 13.3518 11.6879 14.0511 12.0231 14.0511C12.3583 14.0511 12.0866 13.6667 12.6417 13.5093C13.1968 13.3518 13.8643 13.9001 14.3442 13.9001" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
);
