import React from 'react';
import { Cell, Link, Space, Text } from 'just-core-ui';
import { ArrangeSvg20 } from '~/common/icons';
import { Money, Wrapper } from '~/components';
import { PaymentSummary } from '../common';
import { ProductCard } from './product-card';
import { ProductCardRow } from './product-card-row';

export type ProductCardOperationsProps = {
    paymentSummary: PaymentSummary;
    onClick?: () => void;
};

export function ProductCardOperations(props: ProductCardOperationsProps): JSX.Element {
    return (
        <Wrapper>
            <ProductCard
                title={'Операции'}
                icon={ArrangeSvg20}
            >
                <Text
                    size={'3xl'}
                    weight={'medium'}
                    height={'s'}
                >
                    {props.paymentSummary.count}
                </Text>
                <ProductCardRow
                    title={'Зачислено:'}
                    value={<Money amount={props.paymentSummary.amount}/>}
                />
                {Boolean(props.onClick) && (
                    <>
                        <Space h={'xs'}/>
                        <Cell
                            paddingY={'xs'}
                        >
                            <Text
                                size={'s'}
                                height={'s'}
                            >
                                <Link onClick={props.onClick}>
                                    Все операции
                                </Link>
                            </Text>
                        </Cell>
                    </>
                )}
            </ProductCard>
        </Wrapper>
    );
}
