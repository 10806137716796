import React from 'react';
import { Button, Space } from 'just-core-ui/components';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { Wrapper } from '~/components';

export function HomeAddProduct(): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const handleNewClick = () => jpmActions.pushLayer(
        'MODAL',
        'PRODUCT_NEW',
    );

    return (
        <Wrapper>
            <Space h={'l'}/>
            <Button
                size={'l'}
                onClick={handleNewClick}
                autoFill
            >
                Оформить новый займ
            </Button>
        </Wrapper>
    );
}
