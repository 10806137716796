import React from 'react';
import { SvgIcon } from '~/components';

export const ArrangeSvg20 = ({ color, size = 20 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8334 6.58092L13.4192 4.16675" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.4192 8.99414L15.8334 6.58081" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.16675 6.5835H15.8334" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.16675 13.4192L6.58092 15.8334" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.58092 11.0059L4.16675 13.4192" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.8334 13.4167H4.16675" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
);
