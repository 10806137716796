import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { JustPageManagerContext } from './just-page-manager-context';

const useStyles = createUseStyles({
    JustPageManagerMap: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        padding: 20,
        position: 'absolute',
        left: 0,
        bottom: 0,
        zIndex: 1000,
    },
});

export function JustPageManagerMap(): JSX.Element {
    const { state, actions } = useContext(JustPageManagerContext);

    const pushLayer = () => actions.pushLayer('RIGHT', 'MORE', { a: Math.random() });
    const popLayer = () => actions.popLayer();
    const pushPage = () => actions.pushPage('LOL', { a: Math.random() });
    const popPage = () => actions.popPage();

    const styles = useStyles();

    return (
        <div className={styles.JustPageManagerMap}>
            <ul>
                {state.layers.map((layer, index) => (
                    <li key={index}>
                        {layer.type}
                        <ol>
                            {layer.pages.map((page, index) => (
                                <li key={index}>
                                    {page.key} – {page.props['a']}
                                </li>
                            ))}
                        </ol>
                    </li>
                ))}
            </ul>
            <br/>
            <button onClick={pushLayer}>
                PUSH LAYER
            </button>
            <button onClick={popLayer}>
                POP LAYER
            </button>
            <br/>
            <button onClick={pushPage}>
                PUSH PAGE
            </button>
            <button onClick={popPage}>
                POP PAGE
            </button>
        </div>
    );
}
