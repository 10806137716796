import { Payment, PaymentSummary, TitledList } from '../common';
import { mapPayment, PaymentRepository, TitledListService } from '~/common/product';
import { getMonth, getUnixTime, getYear, format } from 'date-fns';
import { getSessionKey } from '~/common/session';

export class PaymentService {
    static readonly instance = new PaymentService();

    async getPayments(customerId: number, loanKey: string): Promise<Array<Payment>> {
        const appPayments = await PaymentRepository.instance.appPaymentGetList({
            session_key: getSessionKey(),
            customer_id: customerId,
            loan_key: loanKey,
        });

        return appPayments
            .map(mapPayment)
            .sort((a, b) => getUnixTime(a.date) - getUnixTime(b.date));
    }

    getSummary(payments: Array<Payment>): PaymentSummary {
        return {
            count: payments.length,
            amount: String(payments.reduce((amount: number, payment: Payment) => Number(payment.amount) + amount, 0)),
        };
    }

    makeTitledList(scheduledPayments: Array<Payment>): Array<TitledList<Payment>> {
        return TitledListService.instance.make(
            scheduledPayments,
            scheduledPayment => `${format(scheduledPayment.date, 'M yyyy').replace(' ', '-')}`
        );
    }
}
