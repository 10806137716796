import React, {useEffect, useState} from 'react';
import {differenceInSeconds} from 'date-fns';
import {Button, Input, Link, Space} from 'just-core-ui';
import {SessionActions, SessionSelectors} from '~/common/session';
import {TextSessionDescription, TextSessionTitle} from '~/components';
import {
    useActions,
    useInput,
    UseInput,
    useInputModifierLimiter,
    useInputModifierOnlyNumbersAndDash,
    useInputValidatorMinLength,
} from '~/hooks';
import {SessionLayout} from './session-layout';

const TOKEN_RELOAD_SECONDS = 10;

export type SessionPageCodeProps = {
    phone: UseInput;
}

export function SessionPageCode(props: SessionPageCodeProps): JSX.Element {
    const authToken = SessionSelectors.authToken();
    const authTokenCreatedAt = SessionSelectors.authTokenCreatedAt();
    const authTokenLoading = SessionSelectors.authTokenLoading();
    const authKeyLoading = SessionSelectors.authKeyLoading();

    const sessionActions = useActions(SessionActions);

    const [nextCodeTimer, setNextCodeTimer] = useState(TOKEN_RELOAD_SECONDS);

    const code = useInput('', {
        modifiers: [
            useInputModifierOnlyNumbersAndDash(),
            useInputModifierLimiter(7),
        ],
        validators: [
            useInputValidatorMinLength(7),
        ],
    });

    //It basically excludes anything other than number, but in our case it's dash
    const excludeDashFromStr = (str: string): string => str.replace(/\D/gi, '');

    const checkAuthToken = () => sessionActions.checkAuthToken(authToken, excludeDashFromStr(code.value));

    const createAuthToken = () => sessionActions.createAuthToken(props.phone.value);

    const onEnterKeyPress = (event) => {
        if (code.validation.isValid && event.key === 'Enter') {
            checkAuthToken();
        }
    };

    useEffect(() => {
        const inputElement = document.getElementsByTagName('input')[0];
        inputElement.id = 'single-factor-code-text-field';
        inputElement.autocomplete = 'one-time-code';

        document.addEventListener("keydown", onEnterKeyPress);
        const interval = setInterval(() => {
            const diff = differenceInSeconds(new Date(), authTokenCreatedAt);

            const seconds = TOKEN_RELOAD_SECONDS - diff;

            setNextCodeTimer(seconds);

            if (seconds < 1) {
                clearInterval(interval);
            }
        }, 500);

        return () => {
            clearInterval(interval);
            document.removeEventListener("keydown", onEnterKeyPress);
        }
    }, [authTokenCreatedAt, code.value]);

    return (
        <SessionLayout>
            <Space h={'l'}/>
            <TextSessionTitle>
                Для завершения авторизации
                <br/>
                введите СМС код
            </TextSessionTitle>
            <Space h={'s'}/>
            <TextSessionDescription>
                Мы выслали 6 значный код на номер {props.phone.value}.
                <br/>
                Если код не пришел, запросите его еще раз.
            </TextSessionDescription>
            <Space h={'2xl'}/>
            <Input
                title={'СМС код'}
                value={code.value}
                onChange={code.onChange}
                bottom={(
                    <div>
                        {nextCodeTimer > 0 && (
                            <>
                                Следующий код через: {nextCodeTimer}
                            </>
                        )}
                        {nextCodeTimer <= 0 && (
                            <>
                                <Link
                                    onClick={createAuthToken}
                                    disabled={authTokenLoading}
                                >
                                    Выслать код еще раз
                                </Link>
                            </>
                        )}
                    </div>
                )}
            />
            <Space h={'m'}/>
            <Button
                onClick={checkAuthToken}
                loading={authKeyLoading}
                disabled={!code.validation.isValid || authTokenLoading}
                size={'l'}
                autoFill
            >
                Дальше
            </Button>
        </SessionLayout>
    );
}
