import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorToBot, StyleService, Theme, useTheme } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { BackSvg24, CloseSvg24 } from '~/common/icons';

const HEADER_HEIGHT = 40;

export type JpmLayoutModalProps = {
    pages: Array<any>;
    render: (page: any) => React.ReactNode;
    hidePopLayerButton?: boolean;
    isTopLayer?: boolean;
    darkMode?: boolean;
};

const useStyles = createUseStyles({
    JpmLayoutModal: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    JpmLayoutModal__Bg: (props: JpmLayoutModalProps & { theme: Theme }) => ({
        backgroundColor: props.isTopLayer
            ? colorToBot(props.theme, { opacity: 0.8 }, '#000000')
            : null,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: 'pointer',
    }),
    JpmLayoutModal__Window: (props: JpmLayoutModalProps & { theme: Theme }) => ({
        borderRadius: StyleService.instance.getRadius(props.theme, 'l'),
        backgroundColor: StyleService.instance.getTopColor(props.theme),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '@media (min-width: 601px)': {
            left: '50%',
            right: 'auto',
            width: 420,
            marginLeft: -210,
        },
        '@media (min-height: 601px)': {
            top: 100,
            bottom: 'auto',
        },
        '@media (min-height: 801px)': {
            top: 150,
            bottom: 'auto',
        },
    }),
    JpmLayoutModal__Head: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: HEADER_HEIGHT,
    },
    JpmLayoutModal__Head_Left: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
    },
    JpmLayoutModal__Head_Right: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
    },
    JpmLayoutModal__Body: {
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        bottom: 0,
        '@media (min-height: 601px)': {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            paddingTop: HEADER_HEIGHT,
        },
    },
    JpmLayoutModal__Body_Content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '@media (min-height: 601px)': {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxHeight: 400,
        },
        '@media (min-height: 801px)': {
            maxHeight: 600,
        },
        overflow: 'hidden',
        overflowY: 'auto',
        display: 'none',
        '&:last-child': {
            display: 'block',
        },
    },
    JpmLayoutModal__Button: {
        width: HEADER_HEIGHT,
        height: HEADER_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
});

export function JpmLayoutModal(props: JpmLayoutModalProps): JSX.Element {
    const theme = useTheme();

    const actions = useJustPageManagerActions();

    const styles = useStyles({
        ...props,
        theme,
    });

    return (
        <div className={styles.JpmLayoutModal}>
            <div
                className={styles.JpmLayoutModal__Bg}
                onClick={actions.popLayer}
            />
            <div className={styles.JpmLayoutModal__Window}>
                <div className={styles.JpmLayoutModal__Body}>
                    {props.pages.map((page, index) => (
                        <div className={styles.JpmLayoutModal__Body_Content} key={index}>
                            {props.render(page)}
                        </div>
                    ))}
                </div>
                <div className={styles.JpmLayoutModal__Head}>
                    <div className={styles.JpmLayoutModal__Head_Left}>
                        {props.pages.length > 1 && (
                            <div
                                className={styles.JpmLayoutModal__Button}
                                onClick={actions.popPage}
                            >
                                <BackSvg24 color={StyleService.instance.getBotColor(theme)}/>
                            </div>
                        )}
                    </div>
                    <div className={styles.JpmLayoutModal__Head_Right}>
                        {!props.hidePopLayerButton && (
                            <div
                                className={styles.JpmLayoutModal__Button}
                                onClick={actions.popLayer}
                            >
                                <CloseSvg24 color={StyleService.instance.getBotColor(theme)}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
