import React, {useEffect, useCallback} from 'react';
import { Button, Input, Space } from 'just-core-ui';
import { SessionActions, SessionSelectors } from '~/common/session';
import { TextSessionDescription, TextSessionTitle } from '~/components';
import { useActions, UseInput, phoneMask } from '~/hooks';
import { SessionLayout } from './session-layout';

export type SessionPageLoginProps = {
    phone: UseInput;
}

export function SessionPageLogin(props: SessionPageLoginProps): JSX.Element {
    const authTokenLoading = SessionSelectors.authTokenLoading();

    const sessionActions = useActions(SessionActions);

    const createAuthToken = () => sessionActions.createAuthToken(props.phone.value);

    const onEnterKeyPress = (event) => {
      if(props.phone.validation.isValid && event.key === 'Enter') {
        createAuthToken();
      };
    };

    useEffect(() => {
      document.addEventListener("keydown", onEnterKeyPress);

      return () => document.removeEventListener("keydown", onEnterKeyPress);
    }, [props.phone.value]);

    return (
        <SessionLayout>
            <Space h={'l'}/>
            <TextSessionTitle>
                Вход в личный кабинет
                <br/>
                «Папа Финанс»
            </TextSessionTitle>
            <Space h={'s'}/>
            <TextSessionDescription>
                Авторизация – это всегда хорошая затея.
            </TextSessionDescription>
            <Space h={'2xl'}/>
            <Input title={'Номер телефона'} onChange={props.phone.onChange} value={phoneMask(props.phone.value)}/>
            <Space h={'m'}/>
            <Button
                onClick={createAuthToken}
                loading={authTokenLoading}
                disabled={!props.phone.validation.isValid}
                size={'l'}
                autoFill
            >
                Дальше
            </Button>
        </SessionLayout>
    );
}
