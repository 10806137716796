import { NotifyActions } from '~/common/notify';
import { Customer, SessionRepository } from '~/common/session';
import { createDataWithSessionKey, getSessionKey, parseUser, setSessionKey } from '../utils';
import { sessionSlice } from './session-slice';

export const SessionActions = {
    check: () => async (dispatch) => {
        try {
            dispatch(sessionSlice.actions.checkStart());

            const result = await SessionRepository.instance.check(createDataWithSessionKey());

            dispatch(sessionSlice.actions.checkSuccess(result ? parseUser(result) : null));
        } catch (e) {
            dispatch(sessionSlice.actions.checkError());

            console.error('SessionActions.check ERROR', e);

            dispatch(NotifyActions.notify({
                type: 'error',
                message: e.message,
            }));
        }
    },
    createAuthToken: (phone: string) => async (dispatch) => {
        try {
            dispatch(sessionSlice.actions.createAuthTokenStart());

            const result = await SessionRepository.instance.createAuthToken({ phone });

            dispatch(sessionSlice.actions.createAuthTokenSuccess(result));
        } catch (e) {
            dispatch(sessionSlice.actions.createAuthTokenError());

            console.error('SessionActions.createAuthToken ERROR', e);

            dispatch(NotifyActions.notify({
                type: 'error',
                message: e.message,
            }));
        }
    },
    checkAuthToken: (key: string, code: string) => async (dispatch) => {
        try {
            dispatch(sessionSlice.actions.checkAuthTokenStart());

            const result = await SessionRepository.instance.checkAuthToken({ key, code });

            if (result) {
                dispatch(sessionSlice.actions.checkAuthTokenSuccess());

                setSessionKey(result);
            } else {
                dispatch(sessionSlice.actions.checkAuthTokenError());
            }
        } catch (e) {
            dispatch(sessionSlice.actions.checkAuthTokenError());

            console.error('SessionActions.checkAuthToken ERROR', e);

            dispatch(NotifyActions.notify({
                type: 'error',
                message: e.message,
                code: e.code,
            }));
        }
    },
    createInnRequest: (inn: string) => async (dispatch) => {
        try {
            dispatch(sessionSlice.actions.createInnRequestStart());

            const result = await SessionRepository.instance.createInnRequest({
                session_key: getSessionKey(),
                inn,
            });

            if (result) {
                dispatch(sessionSlice.actions.createInnRequestSuccess());
            } else {
                dispatch(sessionSlice.actions.createInnRequestError());
            }
        } catch (e) {
            dispatch(sessionSlice.actions.createInnRequestError());

            console.error('SessionActions.createInnRequest ERROR', e);

            dispatch(NotifyActions.notify({
                type: 'error',
                message: e.message,
            }));
        }
    },
    selectCustomer: (customer: Customer) => async (dispatch) => {
        dispatch(sessionSlice.actions.selectCustomer(customer));
    },
};
