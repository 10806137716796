import React from 'react';
import { JustPageManagerLayer, JustPageManagerPage } from '../common';
import { JustPageManagerPageView } from './just-page-manager-page-view';

export type JustPageManagerLayerViewProps = {
    pages: Record<string, React.FC<any>>;
    layouts: Record<string, React.FC<any>>;
    layer: JustPageManagerLayer;
    hidePopLayerButton?: boolean;
    isTopLayer?: boolean;
};

export function JustPageManagerLayerView(props: JustPageManagerLayerViewProps): JSX.Element {
    const Layout = props.layouts[props.layer.type];

    return (
        <Layout
            pages={props.layer.pages}
            render={(page: JustPageManagerPage) => (
                <JustPageManagerPageView
                    pages={props.pages}
                    page={page}
                />
            )}
            hidePopLayerButton={props.hidePopLayerButton}
            isTopLayer={props.isTopLayer}
        />
    );
}
