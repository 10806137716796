import React, { useMemo, useState } from 'react';
import {
    JustPageManagerContextValue,
    JustPageManagerLayer,
    JustPageManagerPage,
    JustPageManagerState,
} from '../common';
import {
    createLayer,
    createPage,
    popLayerFromState,
    popPageFromState,
    pushLayerToState,
    pushPageToState,
} from '../utils';
import { JustPageManagerContext } from './just-page-manager-context';
import { JustPageManagerView } from './just-page-manager-view';

const EMPTY_PROPS = {};

export type JustPageManagerProps = {
    defaultLayer: string;
    defaultPage: string;
    pages: Record<string, React.FC<any>>;
    layouts: Record<string, React.FC<any>>;
    showMap?: boolean;
};

const createDefaultState = (props: JustPageManagerProps): JustPageManagerState => ({
    layers: [
        {
            type: props.defaultLayer,
            pages: [
                {
                    key: props.defaultPage,
                    props: { a: Math.random() },
                },
            ],
        },
    ],
});

export function JustPageManager(props: JustPageManagerProps): JSX.Element {
    const [state, setState] = useState<JustPageManagerState>(createDefaultState(props));

    const pushLayer = (
        type: JustPageManagerLayer['type'],
        key: JustPageManagerPage['key'],
        props?: JustPageManagerPage['props'],
    ) => setState(pushLayerToState(state, createLayer(type, key, props || EMPTY_PROPS)));

    const popLayer = () => setState(popLayerFromState(state));

    const pushPage = (
        key: JustPageManagerPage['key'],
        props?: JustPageManagerPage['props'],
    ) => setState(pushPageToState(state, createPage(key, props || EMPTY_PROPS)));

    const popPage = () => setState(popPageFromState(state));

    const justPageManagerContextValue = useMemo<JustPageManagerContextValue>(() => ({
        state,
        actions: {
            pushLayer,
            popLayer,
            pushPage,
            popPage,
        },
    }), [state]);

    return (
        <JustPageManagerContext.Provider value={justPageManagerContextValue}>
            <JustPageManagerView
                pages={props.pages}
                layouts={props.layouts}
                showMap={props.showMap}
            />
        </JustPageManagerContext.Provider>
    );
}
