import React from 'react';
import { Container } from 'just-core-ui';

type ContainerSmallProps = {
    children?: React.ReactNode;
};

export function ContainerSmall(props: ContainerSmallProps): JSX.Element {
    return (
        <Container
            maxWidth={400}
            paddingX={'s'}
        >
            <div>
                {props.children}
            </div>
        </Container>
    );
}
