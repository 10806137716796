import React from 'react';
import { Space } from 'just-core-ui';
import { Customer, SessionActions, SessionSelectors } from '~/common/session';
import { TextSessionDescription, TextSessionTitle } from '~/components';
import { SessionLayout } from './session-layout';
import { SessionCustomerRow } from '~/common/session/components/session-customer-row';
import { useActions } from '~/hooks';

export function SessionPageCustomers(): JSX.Element {
    const user = SessionSelectors.user();

    const sessionActions = useActions(SessionActions);

    const selectCustomer = (customer: Customer) => () => sessionActions.selectCustomer(customer);

    return (
        <SessionLayout>
            <Space h={'l'}/>
            <TextSessionTitle>
                Выбор организации
            </TextSessionTitle>
            <Space h={'s'}/>
            <Space h={'2xl'}/>
            {!!user.customers.length && (
                <>
                    {user.customers.map(customer => (
                        <React.Fragment key={customer.inn}>
                            <SessionCustomerRow
                                title={customer.name}
                                inn={customer.inn}
                                onClick={selectCustomer(customer)}
                            />
                        </React.Fragment>
                    ))}
                </>
            )}
        </SessionLayout>
    );
}
