import React, { useEffect } from 'react';
import { JustPageManager } from '~/common/just-page-manager';
import { Session, SessionSelectors } from '~/common/session';
import { ProductActions } from '~/common/product';
import { Notify } from '~/common/notify';
import { useActions } from '~/hooks';
import { JpmLayouts, JpmPages } from '~/jpm';

export function App(): JSX.Element {
    const customer = SessionSelectors.customer();

    const productActions = useActions(ProductActions);

    useEffect(() => {
        if (customer) {
            productActions.getList(customer.id);
            productActions.getLoanRequestsList(customer.id);
        }
    }, [customer]);

    return (
        <>
            <Session>
                <JustPageManager
                    defaultLayer={'BASE'}
                    defaultPage={'HOME'}
                    pages={JpmPages}
                    layouts={JpmLayouts}
                />
            </Session>
            <Notify/>
        </>
    );
}
