import React from 'react';
import { Space, Text } from 'just-core-ui';
import { Loan, ProductCard, ProductCardRow, ProductSelector } from '~/common/product';
import { formatDate } from '~/utils';
import { ContainerPage, FooterEmpty, Money, Wrapper } from '~/components';
import { useJustPageManagerActions } from '~/common/just-page-manager';

export function ProductArchive(): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const loans = ProductSelector.loansArchive();

    const openProduct = (loan: Loan) => () => jpmActions.pushPage(
        'PRODUCT',
        { loan }
    );

    return (
        <ContainerPage>
            <Wrapper>
                <Text size={'3xl'} weight={'bold'} height={'s'}>
                    Архив
                </Text>
                <Space h={'l'}/>
                {loans.map(loan => (
                    <div key={loan.key}>
                        <Space h={'m'}/>
                        <ProductCard
                            title={'Тип займа'}
                            onClick={openProduct(loan)}
                        >
                            <ProductCardRow
                                title={'Сумма займа:'}
                                value={<Money amount={loan.initTotalAmount}/>}
                            />
                            <ProductCardRow
                                title={'Дата закрытия:'}
                                value={formatDate(loan.initEndDate)}
                            />
                        </ProductCard>
                    </div>
                ))}
            </Wrapper>
            <FooterEmpty/>
        </ContainerPage>
    );
}
