import React from 'react';
import { ProductType, ProductTypeService } from '~/common/product';
import { ContainerPage, FooterEmpty, Wrapper } from '~/components';
import { Button, Space, Text } from 'just-core-ui/components';
import { useJustPageManagerActions } from '~/common/just-page-manager';

export type ProductAboutProps = {
    type: ProductType;
};

export function ProductAbout(props: ProductAboutProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const { type } = props;

    const handleNewClick = () => jpmActions.pushPage(
        'PRODUCT_NEW',
        {
            type,
        },
    );

    return (
        <ContainerPage>
            <Wrapper>
                <Space h={'m'}/>
                <img
                    src={ProductTypeService.instance.getIcon(props.type)}
                    width={110}
                    height={110}
                />
                <Space h={'xl'}/>
                <Space h={'xs'}/>
                <Text
                    size={'xl'}
                    weight={'bold'}
                >
                    {ProductTypeService.instance.getTitle(props.type)}
                </Text>
                <Space h={'xs'}/>
                <Text>
                    Финансирование поставщиков под оборот  на маркетплейсах OZON и Wildberries.
                </Text>
                <Space h={'xl'}/>
                <ul
                    style={{
                        margin: 0,
                        padding: 0,
                        marginLeft: 20,
                    }}
                >
                    <li>
                        <Text>
                            Минимум документов
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Оформление онлайн
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Без комиссий и переплат
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Удобный график платежей
                        </Text>
                    </li>
                </ul>
                <Space h={'3xl'}/>
                <Button
                    onClick={handleNewClick}
                    size={'l'}
                    autoFill
                >
                    Оформить займ
                </Button>
            </Wrapper>
            <FooterEmpty/>
        </ContainerPage>
    );
}
