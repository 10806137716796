import React from 'react';
import { SvgIcon } from '~/components';

export const DocTextSvg20 = ({ color, size = 20 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8334 6.66667H12.5001C12.0401 6.66667 11.6667 6.29333 11.6667 5.83333V2.5M6.66675 9.16667H11.6667M6.66675 11.6667H11.6667M6.66675 14.1667H10.2751M15.3451 5.345L12.9884 2.98833C12.6759 2.67583 12.2517 2.5 11.8101 2.5H5.83341C4.91258 2.5 4.16675 3.24583 4.16675 4.16667V15.8333C4.16675 16.7542 4.91258 17.5 5.83341 17.5H14.1667C15.0876 17.5 15.8334 16.7542 15.8334 15.8333V6.52333C15.8334 6.08167 15.6576 5.6575 15.3451 5.345Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);
