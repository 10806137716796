import { TitledList } from '../common';

export class TitledListService {
    static readonly instance = new TitledListService();

    make<T>(list: Array<T>, fn: (item: T) => string): Array<TitledList<T>> {
        const listOfTitledList: Array<TitledList<T>> = [];

        for (let i = 0; i < list.length; i++) {
            const title = fn(list[i]);

            if (
                listOfTitledList.length === 0
                || listOfTitledList[listOfTitledList.length - 1].title !== title
            ) {
                listOfTitledList.push({
                    title,
                    list: [],
                })
            }

            const l = listOfTitledList.find(el => el.title === title)
            
            if (l != null) {
                l.list.push(list[i]);
            }
            
        }

        return listOfTitledList;
    }
}
