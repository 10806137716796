import React from 'react';
import { createUseStyles } from 'react-jss';
import { StyleService, Theme, useTheme } from 'just-core-ui';
import { NotifySelector } from '../state';
import { NotifyNotification } from './notify-notification';

const useStyles = createUseStyles({
    Notify: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: 0,
    },
    Notify__Container: {
        position: 'absolute',
        top: 0,
        right: 0,
        maxHeight: '100vh',
        // overflow: 'hidden',
        // border: '10px solid tomato',
        // boxSizing: 'border-box',
    },
    Notify__Item: (theme: Theme) => ({
        paddingTop: StyleService.instance.getSpace(theme, 's'),
        paddingRight: StyleService.instance.getSpace(theme, 's'),
    }),
});

export function Notify(): JSX.Element {
    const theme = useTheme();

    const notifications = NotifySelector.notifications();

    const styles = useStyles(theme);

    return (
        <div className={styles.Notify}>
            <div className={styles.Notify__Container}>
                {Boolean(notifications.length) && notifications.map(notification => (
                    <div className={styles.Notify__Item} key={notification.id}>
                        <NotifyNotification notification={notification}/>
                    </div>
                ))}
            </div>
        </div>
    );
}
