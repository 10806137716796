import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeService, ThemeState } from 'just-core-ui';
import { App } from '~/components';
import { configureStore } from '~/redux';
import Ym from './components/ym';

ThemeService.instance.addColors({
    // PAPA_GRAPE: '#903d8d',
    PAPA_GRAPE: '#49bb72',
});

const params = {
    BUTTON_PRIMARY_COLOR: 'PAPA_GRAPE',
    LINK_PRIMARY_COLOR: 'PAPA_GRAPE',
    TOGGLE_PRIMARY_COLOR: 'PAPA_GRAPE',
    PRIMARY_COLOR: 'PAPA_GRAPE',
};

ThemeState.params$.next({
    ...ThemeState.params$.value,
    ...params,
});

ThemeState.invert$.next(false);

// ThemeService.instance.addParams({
//     BUTTON_PRIMARY_COLOR: 'PAPA_GRAPE',
//     LINK_PRIMARY_COLOR: 'PAPA_GRAPE',
//     PRIMARY_COLOR: 'PAPA_GRAPE',
// });

// tc.setColor('PAPA_GRAPE', '#750271');
// tc.setColor('PAPA_GRAPE', '#903d8d');
// tc.setParam('BUTTON_PRIMARY_COLOR', 'PAPA_GRAPE');
// tc.setParam('LINK_PRIMARY_COLOR', 'PAPA_GRAPE');
// tc.setParam('PRIMARY_COLOR', 'PAPA_GRAPE');

const store = configureStore();

ReactDOM.render((
    <Provider store={store}>
        <Ym/>
        <App/>
    </Provider>
), document.getElementById('root'));
