import React from 'react';
import { createUseStyles } from 'react-jss';
import { StyleService, Theme, useTheme } from 'just-core-ui';
import { PapaImage } from '~/common/papa-image';
import { ContainerSmall } from '~/components';

const HEADER_HEIGHT = 80;

export type SessionLayoutProps = {
    children: React.ReactNode;
};

const useStyles = createUseStyles({
    SessionLayout: (theme: Theme) => ({
        backgroundColor: StyleService.instance.getTopColor(theme),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }),
    SessionLayout__Head: (theme: Theme) => ({
        backgroundColor: StyleService.instance.getTopColor(theme),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: HEADER_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    SessionLayout__Space: {
        height: HEADER_HEIGHT,
    },
});

export function SessionLayout(props: SessionLayoutProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles(theme);

    return (
        <div className={styles.SessionLayout}>
            <div className={styles.SessionLayout__Head}>
                <img
                    src={PapaImage.LOGO}
                    alt={'LOGO'}
                />
            </div>
            <div className={styles.SessionLayout__Space}/>
            <ContainerSmall>
                {props.children}
            </ContainerSmall>
        </div>
    );
}
