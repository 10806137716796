import React from 'react';
import { colorToTop, Loader, useTheme } from 'just-core-ui';
import { SessionLayout } from './session-layout';

export function SessionPageLoading(): JSX.Element {
    const theme = useTheme();

    return (
        <SessionLayout>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-30px',
                    marginLeft: '-30px',
                }}
            >
                <Loader
                    size={60}
                    color={colorToTop(theme, 7)}
                />
            </div>
        </SessionLayout>
    );
}
