import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Loan, Payment, ScheduledPayment } from '../common';
import { ProductState } from './product-state';
import { LoanRequestJson } from '~/common/app-api';

const initialState: ProductState = {
    loans: [],
    loansLoading: false,
    paymentsRecord: {},
    scheduledPaymentsRecord: {},
    loanRequests: [],
    loanRequestsLoading: false,
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        // UPDATE PRODUCTS

        getListStart: (state: ProductState) => {
            state.loans = [];
            state.loansLoading = true;
        },
        getListSuccess: (state: ProductState, action: PayloadAction<Array<Loan>>) => {
            state.loans = action.payload;
            state.loansLoading = false;
        },
        getListError: (state: ProductState) => {
            state.loansLoading = false;
        },

        // UPDATE PAYMENTS

        getPaymentListSuccess: (state: ProductState, action: PayloadAction<{ loanKey: string, payments: Array<Payment> }>) => {
            const loanIndex = state.loans.findIndex(loan => loan.key === action.payload.loanKey);

            // state.loans[loanIndex].payments = action.payload.payments;
            state.paymentsRecord[action.payload.loanKey] = action.payload.payments;
        },

        // UPDATE SCHEDULED PAYMENTS

        getScheduledPaymentListSuccess: (state: ProductState, action: PayloadAction<{ loanKey: string, scheduledPayments: Array<ScheduledPayment> }>) => {
            const loanIndex = state.loans.findIndex(loan => loan.key === action.payload.loanKey);

            // state.loans[loanIndex].scheduledPayments = action.payload.scheduledPayments;
            state.scheduledPaymentsRecord[action.payload.loanKey] = action.payload.scheduledPayments;
        },

        // LOAN REQUESTS

        updateLoanRequests: (state: ProductState, action: PayloadAction<Array<LoanRequestJson>>) => {
            state.loanRequests = action.payload;
            state.loanRequestsLoading = false;
        },

        addLoanRequests: (state: ProductState, action: PayloadAction<Array<LoanRequestJson>>) => {
            state.loanRequests = [...state.loanRequests, ...action.payload];
        },
    },
});
