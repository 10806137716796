import { ScheduledPayment, TitledList } from '../common';
import { TitledListService } from '../services';
import { getMonth, getUnixTime, getYear, format } from 'date-fns';
import { mapScheduledPayment, ScheduledPaymentRepository } from '~/common/product';
import { getSessionKey } from '~/common/session';

export class ScheduledPaymentService {
    static readonly instance = new ScheduledPaymentService();

    async getScheduledPayments(customerId: number, loanKey: string): Promise<Array<ScheduledPayment>> {
        const appScheduledPayments = await ScheduledPaymentRepository.instance.appScheduledPaymentGetList({
            session_key: getSessionKey(),
            customer_id: customerId,
            loan_key: loanKey,
        });

        return appScheduledPayments
            .map(mapScheduledPayment)
            .sort((a, b) => getUnixTime(a.initPayDate) - getUnixTime(b.initPayDate));
    }

    makeTitledList(scheduledPayments: Array<ScheduledPayment>): Array<TitledList<ScheduledPayment>> {
        return TitledListService.instance.make(
            scheduledPayments,
            scheduledPayment => `${format(scheduledPayment.initPayDate, 'M yyyy').replace(' ', '-')}`,
        );
    }
}

