import React from 'react';
import { createUseStyles } from 'react-jss';
import {
    Avatar,
    colorToBot,
    colorToTop,
    Space,
    StyleService,
    Text,
    Theme,
    useTheme,
} from 'just-core-ui';
import { Money } from '~/components';

type ProductItemProps = {
    contract_id?: string;
    restAmount: string;
    totalAmount: string;
    avatarColor?: string;
    avatarIcon?: React.ReactNode;
    title: string;
    description?: React.ReactNode;
    descriptionAlert?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    darkMode?: boolean;
};

// @todo сделать скейл при нажатии

const useStyles = createUseStyles({
    ProductItem: (props: ProductItemProps & { theme: Theme }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'start',
        padding: StyleService.instance.getSpace(props.theme, 's'),
        borderRadius: StyleService.instance.getRadius(props.theme, 'l'),
        cursor: props.disabled ? null : 'pointer',
        transition: 'background-color 0.2s ease',
        '&:hover': props.disabled ? null : {
            backgroundColor: colorToTop(props.theme, { opacity: 0.05 }),
        },
    }),
    ProductItem__Title: {
        padding: '3px 0 0 0',
        boxSizing: 'border-box',
    },
    ProductItem__Icon: (props: ProductItemProps & { theme: Theme }) => ({
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: StyleService.instance.getSpace(props.theme, 'l'),
    }),
    ProductItem__TotalAmount: {
        '@media (max-width: 650px)': {
            display: 'block',
        },
    },
});

export function ProductItem(props: ProductItemProps): JSX.Element {
    const theme = useTheme();

    const {
        avatarColor,
    } = props;

    const dangerColor = props.descriptionAlert ? 'red' : void 0;

    const styles = useStyles({
        ...props,
        theme,
    });

    // @todo не разрывать строку с цифрами
    return (
        <div className={styles.ProductItem} onClick={props.disabled ? null : props.onClick}>
            <div className={styles.ProductItem__Icon}>
                <Avatar
                    size={'m'}
                    borderRadius={'m'}
                    color={avatarColor}
                >
                    {props.avatarIcon}
                </Avatar>
            </div>
            <div>
                <div className={styles.ProductItem__Title}>
                    <Text
                        size={'3xl'}
                        weight={'medium'}
                        height={'m'}
                        tag={'span'}
                        color={colorToTop(theme, props.disabled ? 6 : 0)}
                    >
                        <Money amount={props.restAmount}/>
                    </Text>
                    {Boolean(props.totalAmount) && (
                        <Text
                            size={'3xl'}
                            weight={'medium'}
                            height={'m'}
                            tag={'span'}
                            color={colorToTop(theme, 6)}
                        >
                            {' '}
                            <span className={styles.ProductItem__TotalAmount}>
                                из
                                {' '}
                                <Money amount={props.totalAmount}/>
                            </span>
                        </Text>
                    )}
                </div>
                <Space h={'s'}/>
                <Text
                    size={'m'}
                    weight={'medium'}
                    height={'s'}
                    color={props.disabled ? colorToTop(theme, 6) : colorToBot(theme, 6)}
                >
                    {props.title}
                </Text>
                {props.contract_id &&
                  <Text
                    size={'m'}
                    weight={'medium'}
                    height={'s'}
                    color={colorToBot(theme, 6)}
                  >
                    {props.contract_id}
                  </Text>
                }
                {props.description && (
                    <>
                        <Space h={'xs'}/>
                        <Text
                            size={'s'}
                            weight={'regular'}
                            height={'s'}
                            color={props.disabled ? colorToTop(theme, 6) : dangerColor}
                        >
                            {props.description}
                        </Text>
                    </>
                )}
            </div>
        </div>
    );
}
