import React from 'react';
import { Button, Input, Space } from 'just-core-ui';
import { SessionActions, SessionSelectors } from '~/common/session';
import { TextSessionDescription, TextSessionTitle } from '~/components';
import {
    useActions,
    useInput,
    UseInput,
    useInputModifierLimiter,
    useInputModifierOnlyNumbers,
    useInputValidatorMinLength,
} from '~/hooks';
import { SessionLayout } from './session-layout';

export function SessionPageInn(): JSX.Element {
    const innRequestLoading = SessionSelectors.innRequestLoading();

    const sessionActions = useActions(SessionActions);

    const inn = useInput('', {
        modifiers: [
            useInputModifierOnlyNumbers(),
            useInputModifierLimiter(12),
        ],
        validators: [
            useInputValidatorMinLength(10),
        ],
    });

    const checkAuthToken = () => sessionActions.createInnRequest(inn.value);

    return (
        <SessionLayout>
            <Space h={'l'}/>
            <TextSessionTitle>
                Нет организаций
            </TextSessionTitle>
            <Space h={'s'}/>
            <TextSessionDescription>
                Введите ИНН юрлица для подачи запроса.
            </TextSessionDescription>
            <Space h={'2xl'}/>
            <Input
                title={'ИНН'}
                value={inn.value}
                onChange={inn.onChange}
            />
            <Space h={'m'}/>
            <Button
                onClick={checkAuthToken}
                loading={innRequestLoading}
                disabled={!inn.validation.isValid}
                size={'l'}
                autoFill
            >
                Дальше
            </Button>
        </SessionLayout>
    );
}
