import { AppScheduledPaymentJson } from '~/common/app-api';
import { ScheduledPayment } from '../common';

export function mapScheduledPayment(data: AppScheduledPaymentJson): ScheduledPayment {
    const scheduledPayment = new ScheduledPayment();

    scheduledPayment.initTotalAmount = String(data.init_total_amount);
    scheduledPayment.initLoanAmount = String(data.init_loan_amount);
    scheduledPayment.initPercentAmount = String(data.init_percent_amount);
    scheduledPayment.initCommissionAmount = String(data.init_commission_amount);
    scheduledPayment.initNumber = Number(data.init_number);
    scheduledPayment.initPayDate = new Date(data.init_pay_date);

    scheduledPayment.currentTotalAmount = String(data.current_total_amount);
    scheduledPayment.currentLoanAmount = String(data.current_loan_amount);
    scheduledPayment.currentPercentAmount = String(data.current_percent_amount);
    scheduledPayment.currentCommissionAmount = String(data.current_commission_amount);
    scheduledPayment.currentPennyAmount = String(data.current_penny_amount);

    return scheduledPayment;
}
