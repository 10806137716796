import React, { FC, useEffect } from "react";

declare global {
    interface Window {
        carrotquest: any
        carrotquestasync: Array<any>
    }
}

const carrotApiKey: string = process.env.CARROT_API_KEY;
const initializeCarrotQuest = (t) => {
    return function () {
        window.carrotquestasync.push(t, arguments)
    }
}
const Chatbot: FC = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript",
            script.async = !0,
            script.src = "//cdn.carrotquest.app/api.min.js",
            document.head.appendChild(script),
            window.carrotquest = {},
            window.carrotquestasync = [],
            window.carrotquest.settings = {};
        for (var n = ["connect", "track", "identify", "auth", "onReady", "addCallback", "removeCallback", "trackMessageInteraction"], a = 0; a < n.length; a++) {
            window.carrotquest[n[a]] = initializeCarrotQuest(n[a])
        }
        window.carrotquest.connect(carrotApiKey);

        return () => {
            document.head.removeChild(script);
        };

    }, [])

    return (
        <>
        </>
    );
};
export default Chatbot;
