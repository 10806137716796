import { getUnixTime } from 'date-fns';
import { AppLoanJson } from '~/common/app-api';
import { Loan, ProductType } from '../common';
import { mapPayment } from './map-payment';
import { mapScheduledPayment } from './map-scheduled-payment';

const type2productType: Record<string, ProductType> = {
    ACQUIRING_PREPAYMENT: ProductType.ACQUIRING_PREPAYMENT,
    MARKETPLACE_LOAN: ProductType.MARKETPLACE_LOAN,
    ONLINE_STORE_LOAN: ProductType.ONLINE_STORE_LOAN,
    TENDER_LOAN: ProductType.TENDER_LOAN,
    CONTRACT_LOAN: ProductType.CONTRACT_LOAN,
    CARGO_LOAN: ProductType.CARGO_LOAN,
    TURNOVER_LOAN: ProductType.TURNOVER_LOAN,
};

export function mapLoan(data: AppLoanJson): Loan {
    return {
        key: String(data.key),
        contract_number: String(data.contract_number),
        overdue_days: Number(data.overdue_days),
        contract_id: String(data.contract_id),
        initTotalAmount: String(data.init_total_amount),
        initLoanAmount: String(data.init_loan_amount),
        initPercentAmount: String(data.init_percent_amount),
        initCommissionAmount: String(data.init_commission_amount),
        initStartDate: new Date(data.init_start_date),
        initEndDate: new Date(data.init_end_date),

        currentTotalAmount: String(data.current_total_amount),
        currentLoanAmount: String(data.current_loan_amount),
        currentPercentAmount: String(data.current_percent_amount),
        currentCommissionAmount: String(data.current_commission_amount),
        currentPennyAmount: String(data.current_penny_amount),
        currentEndDate: data.current_end_date ? new Date(data.current_end_date) : null,

        factEndDate: data.fact_end_date ? new Date(data.fact_end_date) : null,

        rate: Number(data.rate),
        active: Boolean(data.active),
        productType: type2productType[data.product_type] !== undefined ? type2productType[data.product_type] : null,
        payments: Array.isArray(data.payments)
            ? data.payments.map(mapPayment).sort(
                (a, b) => getUnixTime(a.date) - getUnixTime(b.date)
            )
            : [],
        scheduledPayments: Array.isArray(data.scheduled_payments)
            ? data.scheduled_payments.map(mapScheduledPayment).sort(
                (a, b) => a.initNumber - b.initNumber,
            )
            : [],
    };
}
