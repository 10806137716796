import React from 'react';
import { SvgIcon } from '~/components';

export const CardSvg20 = ({ color, size = 20 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 7.50008H17.5M7.975 10.8334H5.83333M2.5 12.5001V6.50008C2.5 5.21175 3.45917 4.16675 4.6425 4.16675H15.3567C16.5408 4.16675 17.5 5.21175 17.5 6.50008V13.5001C17.5 14.7884 16.5408 15.8334 15.3575 15.8334H12.5M9.16667 15.8334H2.5M9.16667 15.8334L7.5 17.5001M9.16667 15.8334L7.5 14.1667"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);
