import React from 'react';
import { BlockLink, Space } from 'just-core-ui';
import { CardSvg20, RubleSvg20, DocTextSvg20 } from '~/common/icons';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { Wrapper } from '~/components';
import { Loan } from '../common';
import { ProductTypeService } from '../services';

type ProductActionsPanelProps = {
    loan: Loan;
};

export function ProductActionsPanel(props: ProductActionsPanelProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const goToProductPay = (totalPayment: boolean) => () => jpmActions.pushLayer(
        'MODAL',
        'PRODUCT_PAY',
        {
            loan: props.loan,
            totalPayment,
        },
    );

    const goToProductStatement = () => jpmActions.pushLayer(
        'MODAL',
        'PRODUCT_STATEMENT',
        {
            loan: props.loan,
        },
    );

    return (
        <Wrapper>
            <Space h={'m'}/>
            <BlockLink
                onClick={goToProductPay(false)}
                icon={CardSvg20}
                disabled
            >
                Внести платеж
            </BlockLink>
            <BlockLink
                onClick={goToProductPay(true)}
                icon={RubleSvg20}
                disabled
            >
                Закрыть досрочно
            </BlockLink>
            {ProductTypeService.instance.hasStatement(props.loan.productType) && (
                <BlockLink
                    onClick={goToProductStatement}
                    icon={DocTextSvg20}
                    disabled
                >
                    Заказать выписку
                </BlockLink>
            )}
            <Space h={'m'}/>
        </Wrapper>
    );
}
