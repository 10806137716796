import React from 'react';
import { createUseStyles } from 'react-jss';
import { Space, Text } from 'just-core-ui/components';
import { colorToTop, Theme, useTheme } from 'just-core-ui';
import { Money } from './money';

const PROGRESS_COLOR = '#34C759';

export type MoneyProgressProps = {
    already: string;
    total: string;
    progress: number;
};

const useStyles = createUseStyles({
    MoneyProgress: {},
    MoneyProgress__Text: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    MoneyProgress__Bar: (theme: Theme) => ({
        height: 6,
        borderRadius: 3,
        overflow: 'hidden',
        backgroundColor: colorToTop(theme, 6),
    }),
    MoneyProgress__Progress: {
        height: 6,
        backgroundColor: PROGRESS_COLOR,
    },
});

export function MoneyProgress(props: MoneyProgressProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles(theme);

    return (
        <div className={styles.MoneyProgress}>
            <div className={styles.MoneyProgress__Text}>
                <Text
                    size={'s'}
                    height={'s'}
                >
                    <Money amount={props.already}/>
                </Text>
                <Text
                    size={'s'}
                    height={'s'}
                >
                    <Money amount={props.total}/>
                </Text>
            </div>
            <Space h={'xs'}/>
            <div className={styles.MoneyProgress__Bar}>
                <div
                    className={styles.MoneyProgress__Progress}
                    style={{
                        width: `${Math.floor(props.progress * 100)}%`,
                    }}
                />
            </div>
        </div>
    );
}
