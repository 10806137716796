import React from 'react';
import { Text } from 'just-core-ui';

export type TextTitleSessionProps = {
    children: React.ReactNode;
};

export function TextSessionTitle(props: TextTitleSessionProps): JSX.Element {
    return (
        <Text
            size={'2xl'}
            weight={'bold'}
            height={'s'}
            align={'center'}
        >
            {props.children}
        </Text>
    );
}
