import React from 'react';
import { createUseStyles } from 'react-jss';
import { Wrapper } from '~/components';
import { StyleService, Theme, useTheme } from 'just-core-ui';

type ItemGridProps = {
    children?: React.ReactNode;
};

const useStyles = createUseStyles({
    GridItem: {
        display: 'grid',
        'grid-template-columns': '1fr 1fr 1fr',
        gap: `32px 16px`,
        '@media (max-width: 650px)': {
            'grid-template-columns': '1fr 1fr',
        }
    },
});

export function ItemGrid(props: ItemGridProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles();

    return (
        <Wrapper>
            <div className={styles.GridItem}>
                {props.children}
            </div>
        </Wrapper>
    );
}
