import { JsonRpcClient } from '~/common/just-json-rpc-client';
import {
    AppLoanGetListParams,
    AppLoanGetListResult,
    LoanRequestCreateParams,
    LoanRequestCreateResult,
    LoanRequestGetListParams,
    LoanRequestGetListResult,
} from '~/common/app-api';

export class LoanRepository {
    static readonly instance = new LoanRepository();

    private jsonRpcClient: JsonRpcClient;

    constructor() {
        this.jsonRpcClient = new JsonRpcClient('/api', {
            addMethodToUrl: true,
        });
    }

    async appLoanGetList(params: AppLoanGetListParams): Promise<AppLoanGetListResult> {
        return this.jsonRpcClient.call('app-loan-get-list', params);
    }

    async loanRequestCreate(params: LoanRequestCreateParams): Promise<LoanRequestCreateResult> {
        return this.jsonRpcClient.call('loan-request-create', params);
    }

    async loanRequestGetList(params: LoanRequestGetListParams): Promise<LoanRequestGetListResult> {
        return this.jsonRpcClient.call('loan-request-get-list', params);
    }
}
