export const phoneMask = (value: string) :string => {
  let resultValue = '';
    value.slice(0,2) !== '+7'
      ? resultValue = '+' + value
      : resultValue = value;

  if (value.length > 4) {
		resultValue = '+' + value.slice(0, 1) + '(' + value.slice(1, 4) + ')' + '-' + value.slice(4);
  }

  if (value.length > 7) {
    resultValue =
      '+'
      + value.slice(0, 1)
      + '('
      + value.slice(1, 4)
      + ')'
      + '-'
      + value.slice(4, 7)
      + '-'
      + value.slice(7);
  }

  if (value.length > 9) {
    resultValue =
      '+'
      + value.slice(0, 1)
      + '('
      + value.slice(1, 4)
      + ')'
      + '-'
      + value.slice(4, 7)
      + '-'
      + value.slice(7, 9)
      + '-'
      + value.slice(9);
  }

  return resultValue;
}
