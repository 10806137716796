export class ScheduledPayment {
    // INITIAL

    /**
     * Стартовая полная сумма долга (займ + проценты)
     */
    initTotalAmount: string;

    /**
     * Стартовая сумма займа
     */
    initLoanAmount: string;

    /**
     * Стартовая сумма процентов
     */
    initPercentAmount: string;

    /**
     * Стартовая комиссия
     */
    initCommissionAmount: string;

    /**
     * Стартовый номер платежа
     */
    initNumber: number;

    /**
     * Стартовая дата платежа
     */
    initPayDate: Date;

    // CURRENT

    /**
     * Текущая полная сумма выплаты (займ + проценты)
     */
    currentTotalAmount: string;

    /**
     * Текущая сумма выплаты
     */
    currentLoanAmount: string;

    /**
     * Текущая сумма процентов
     */
    currentPercentAmount: string;

    /**
     * Текущая комиссия
     */
    currentCommissionAmount: string;

    /**
     * Текущие пени
     */
    currentPennyAmount: string;

    // OTHER

    /**
     * Текущая полная сумма выплаты в процентах
     */
    get currentTotalPercent(): number {
        return Math.round(Number(this.currentTotalAmount) / Number(this.initTotalAmount) * 100);
    }

    /**
     * Текущая полная сумма к оплате
     */
    get currentTotalDiffAmount(): string {
        return String(Number(this.initTotalAmount) - Number(this.currentTotalAmount));
    }
}
