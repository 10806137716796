const month2text = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
];

export function formatDate(date: Date): string {
    return [
        date.getDate(),
        month2text[date.getMonth()],
        date.getFullYear(),
    ].join(' ');
}

export function formatDateTime(date: Date): string {
    return [
        formatDate(date),
        [
            date.getHours(),
            date.getMinutes(),
        ].join(':'),
    ].join(' – ');
}
