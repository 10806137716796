import React, { useMemo } from 'react';
import { colorToTop, Space, StyleService, Text, Theme, useTheme } from 'just-core-ui';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { ru as locale } from 'date-fns/locale';
import { Loan, ScheduledPayment, ScheduledPaymentService } from '~/common/product';
import { ModalHeader, Money, TextListTitle, Wrapper } from '~/components';
import { GetScheduledPaymentsMessage } from '../../utils/GetScheduledPaymentsMessage';

export type ProductScheduledPaymentsProps = {
    scheduledPayments: Array<ScheduledPayment>;
    loan: Loan;
};

const useStyles = createUseStyles({
    ProductScheduledPayments__ListItem: {
        display: 'flex',
        alignItems: 'center',
    },
    ProductScheduledPayments__Circle: {
        flexBasis: 40,
    },
    ProductScheduledPayments__CircleRound: (theme: Theme) => ({
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: colorToTop(theme, 7),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    ProductScheduledPayments__CircleRoundFull: (theme: Theme) => ({
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: colorToTop(theme, 2, StyleService.instance.getColor(theme, 'GREEN')),
    }),
    ProductScheduledPayments__Content: {
        marginLeft: 20,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export function ProductScheduledPayments(props: ProductScheduledPaymentsProps): JSX.Element {
    const theme = useTheme();

    const titledScheduledPayments = useMemo(
        () => ScheduledPaymentService.instance.makeTitledList(props.scheduledPayments),
        [props.scheduledPayments],
    );

    const totalPaymentsInfo = props.loan.currentTotalAmount || null;
    const additionalMessage = GetScheduledPaymentsMessage(props.loan.overdue_days);

    const styles = useStyles(theme);

    return (
        <div>
            <ModalHeader
                title={'График платежей'}
                description={
                  +totalPaymentsInfo
                  ? `Осталось выплатить ${(parseFloat(`${totalPaymentsInfo}`)).toLocaleString('ru')} ₽`
                  : 'Нет зачисленных платежей'
                }
                message={additionalMessage}
            />
            {titledScheduledPayments.map(titledList => (
                <React.Fragment key={titledList.title}>
                    <Wrapper space={'l'}>
                        <Space h={'s'}/>
                        <TextListTitle>
                            {titledList.title}
                        </TextListTitle>
                        <Space h={'s'}/>
                    </Wrapper>
                    {titledList.list.map(scheduledPayment => (
                        <Wrapper
                            key={scheduledPayment.initNumber}
                            space={'l'}
                        >
                            <Space h={'s'}/>
                            <div className={styles.ProductScheduledPayments__ListItem}>
                                <div className={styles.ProductScheduledPayments__Circle}>
                                    {scheduledPayment.currentTotalPercent === 100 ? (
                                        <div className={styles.ProductScheduledPayments__CircleRoundFull}/>
                                    ) : (
                                        <div className={styles.ProductScheduledPayments__CircleRound}>
                                            <Text>
                                                {scheduledPayment.initNumber}
                                            </Text>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.ProductScheduledPayments__Content}>
                                    <div>
                                        <Text>
                                            {format(scheduledPayment.initPayDate, 'd MMMM', { locale })}
                                        </Text>
                                        {![0, 100].includes(scheduledPayment.currentTotalPercent)
                                        && scheduledPayment.currentTotalDiffAmount !== 'NaN'
                                        ? (
                                            <Text
                                                color={StyleService.instance.getColor(theme, 'RED')}
                                                size={'s'}
                                            >
                                                Внесите ещё <Money amount={scheduledPayment.currentTotalDiffAmount}/>
                                            </Text>
                                        )
                                        : <></>}
                                    </div>
                                    <div>
                                        {scheduledPayment.currentTotalPercent === 0 && (
                                            <Text>
                                                <Money amount={scheduledPayment.initTotalAmount}/>
                                            </Text>
                                        )}
                                        {scheduledPayment.currentTotalPercent === 100 && (
                                            <Text>
                                                <Money amount={scheduledPayment.currentTotalAmount}/>
                                            </Text>
                                        )}
                                            <>
                                                <Text
                                                    size={'s'}
                                                    align={'right'}
                                                >
                                                    <Money amount={scheduledPayment.currentTotalAmount}/>
                                                </Text>
                                            {![0, 100].includes(scheduledPayment.currentTotalPercent)
                                            && scheduledPayment.currentTotalDiffAmount !== 'NaN'
                                                ? <Text
                                                      size={'s'}
                                                      align={'right'}
                                                      color={colorToTop(theme, 2)}
                                                  >
                                                      из <Money amount={scheduledPayment.initTotalAmount}/>
                                                  </Text>
                                                : <></>
                                            }
                                            </>
                                    </div>
                                </div>
                            </div>
                            <Space h={'s'}/>
                        </Wrapper>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}
