import React from 'react';
import { SvgIcon } from '~/components';

export const AvatarSvg64 = ({ color, colorSecondary, size = 64 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="64" height="64" rx="32" fill={color}/>
            <path d="M25.5125 27.2529C25.2018 24.9586 25.6779 22.1875 25.8076 20.1184" stroke={colorSecondary} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M38.7377 26.9782C38.7669 24.8102 38.7935 22.656 38.8849 20.4923" stroke={colorSecondary} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.9751 40.6213C28.9413 46.6229 37.3673 46.3672 44 38.6528" stroke={colorSecondary} strokeWidth="6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
);
