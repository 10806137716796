import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { JustPageManagerContext } from './just-page-manager-context';
import { JustPageManagerLayerView } from './just-page-manager-layer-view';
import { JustPageManagerMap } from './just-page-manager-map';

export type JustPageManagerViewProps = {
    pages: Record<string, React.FC<any>>;
    layouts: Record<string, React.FC<any>>;
    showMap?: boolean;
};

const useStyles = createUseStyles({
    JustPageManagerView: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
});

export function JustPageManagerView(props: JustPageManagerViewProps): JSX.Element {
    const { state } = useContext(JustPageManagerContext);

    const styles = useStyles();

    return (
        <div className={styles.JustPageManagerView}>
            {state.layers.map((layer, index) => (
                <React.Fragment key={index}>
                    <JustPageManagerLayerView
                        pages={props.pages}
                        layouts={props.layouts}
                        layer={layer}
                        hidePopLayerButton={index === 0}
                        isTopLayer={index === state.layers.length - 1}
                    />
                </React.Fragment>
            ))}
            {props.showMap && (
                <JustPageManagerMap/>
            )}
        </div>
    );
}
