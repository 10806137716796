import React from 'react';
import { SvgIcon } from '~/components';

export const CloseSvg24 = ({ color, size = 24 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.34314 6.34315L17.6568 17.6569"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6568 6.34314L6.34314 17.6569"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);
