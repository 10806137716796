import React from 'react';
import { Space, StyleService, useTheme } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import {
    Loan,
    ProductItem,
    ProductSelector,
    ProductTypeService,
} from '~/common/product';
import { CardIconSvg30 } from '~/common/icons/card-icon-svg-30';
import { Heading } from '~/components';
import { HomeAddProduct } from './home-add-product';

export function HomeProducts(): JSX.Element {
    const theme = useTheme();

    const jpmActions = useJustPageManagerActions();

    const loans = ProductSelector.loansActive();

    const loanRequests = ProductSelector.loanRequests();

    const openProduct = (loan: Loan) => () => jpmActions.pushLayer(
        'RIGHT',
        'PRODUCT',
        { loan }
    );

    return (
        <>
            <Heading>
                Мои займы
            </Heading>
            {loans.map((loan, index) => (
                <React.Fragment key={loan.key}>
                    {index > 0 && (
                        <Space h={'s'}/>
                    )}
                    <ProductItem
                        title={ProductTypeService.instance.getTitle(loan.productType)}
                        contract_id={loan.contract_number}
                        onClick={openProduct(loan)}
                        restAmount={loan.currentTotalAmount || loan.initTotalAmount}
                        totalAmount={loan.currentTotalAmount ? loan.initTotalAmount : null}
                        avatarIcon={(
                            <CardIconSvg30
                                color={StyleService.instance.getParamColor(theme, 'PRIMARY_COLOR')}
                            />
                        )}
                    />
                </React.Fragment>
            ))}
            {loans.length > 0 && (
                <Space h={'s'}/>
            )}
            <Heading>
                Мои заявки
            </Heading>
            {loanRequests.map((loanRequest, index) => (
                <React.Fragment key={loanRequest.key}>
                    {index > 0 && (
                        <Space h={'s'}/>
                    )}
                    <ProductItem
                        restAmount={String(loanRequest.amount)}
                        totalAmount={null}
                        title={ProductTypeService.instance.getTitle(Number(loanRequest.type))}
                        description={'Заявка на рассмотрении'}
                        avatarIcon={(
                            <CardIconSvg30
                                color={StyleService.instance.getParamColor(theme, 'PRIMARY_COLOR')}
                            />
                        )}
                        disabled
                    />
                </React.Fragment>
            ))}
            <HomeAddProduct/>
            <Space h={'l'}/>
        </>
    );
}
