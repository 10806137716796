import React, { useEffect } from 'react';
import {
    useActions,
    useInput,
    useInputModifierLimiter,
    useInputModifierOnlyNumbers,
    useInputModifierPhone, useInputValidatorPhone,
} from '~/hooks';
import { SessionActions, SessionSelectors } from '../state';
import { SessionPageCode } from './session-page-code';
import { SessionPageLoading } from './session-page-loading';
import { SessionPageLogin } from './session-page-login';
import { SessionPageInn } from './session-page-inn';
import { SessionPageInnProcess } from './session-page-inn-process';
import { SessionPageCustomers } from '~/common/session/components/session-page-customers';

export type SessionProps = {
    children: React.ReactNode;
};

export function Session(props: SessionProps): JSX.Element {
    const checking = SessionSelectors.checking();
    const user = SessionSelectors.user();
    const authToken = SessionSelectors.authToken();
    const authKeyCount = SessionSelectors.authKeyCount();
    const customer = SessionSelectors.customer();
    const innRequestCount = SessionSelectors.innRequestCount();

    const sessionActions = useActions(SessionActions);

    const phone = useInput('', {
        modifiers: [
            useInputModifierOnlyNumbers(),
            useInputModifierPhone(7),
            useInputModifierLimiter(11),
        ],
        validators: [
            useInputValidatorPhone(),
        ],
    });

    useEffect(() => {
        sessionActions.check();
    }, [
        authKeyCount,
        innRequestCount,
    ]);

    if (!user) {
        if (checking) {
            return (
                <SessionPageLoading/>
            );
        }

        if (authToken) {
            return (
                <SessionPageCode phone={phone}/>
            );
        }

        return (
            <SessionPageLogin phone={phone}/>
        );
    }

    if (!customer) {
        if (user.customers.length) {
            return (
                <SessionPageCustomers/>
            );
        }

        if (user.inns.length) {
            return (
                <SessionPageInnProcess/>
            );
        }

        return (
            <SessionPageInn/>
        );
    }

    return <>{props.children}</>;
}
