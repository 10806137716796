import { PapaImage } from '~/common/papa-image';
import { ProductType } from '../common';

export class ProductTypeService {
    static readonly instance = new ProductTypeService();

    getTitle(productType: ProductType): string {
        const type2title: Record<ProductType, string> = {
            [ProductType.ACQUIRING_PREPAYMENT]: 'Торговый эквайринг',
            [ProductType.MARKETPLACE_LOAN]: 'Маркетплейс',
            [ProductType.ONLINE_STORE_LOAN]: 'Интернет-эквайринг',
            [ProductType.TENDER_LOAN]: 'Участие в тендерах',
            [ProductType.CONTRACT_LOAN]: 'Исполнение контракта',
            [ProductType.CARGO_LOAN]: 'Грузоперевозки',
            [ProductType.TURNOVER_LOAN]: 'Оборот предприятия',
            [ProductType.FACTORING_LOAN]: 'Услуги факторинга',
            [ProductType.TRANSPORT_LEASING]: 'Лизинг транспорта и техники',
            [ProductType.EQUIPMENT_LEASING]: 'Лизинг оборудования',
            [ProductType.LOAN_UNDER_PTS]: 'Заем под ПТС',
            [ProductType.FRANCHISE_LOAN]: 'Заем на франшизу',
        };

        return type2title[productType] || 'Тип займа не определен';
    }

    getColor(productType: ProductType): string {
        const type2color: Record<ProductType, string> = {
            [ProductType.ACQUIRING_PREPAYMENT]: 'LAVENDER',
            [ProductType.MARKETPLACE_LOAN]: 'LIME',
            [ProductType.ONLINE_STORE_LOAN]: 'MINT',
            [ProductType.TENDER_LOAN]: 'ORANGE',
            [ProductType.CONTRACT_LOAN]: 'LIGHT_BLUE',
            [ProductType.CARGO_LOAN]: 'SALMON',
            [ProductType.TURNOVER_LOAN]: 'MAGENTA',
            [ProductType.FACTORING_LOAN]: 'LAVENDER',
            [ProductType.TRANSPORT_LEASING]: 'LAVENDER',
            [ProductType.EQUIPMENT_LEASING]: 'LAVENDER',
            [ProductType.LOAN_UNDER_PTS]: 'LAVENDER',
            [ProductType.FRANCHISE_LOAN]: 'LAVENDER',
        };

        return type2color[productType] || 'PAPA_GRAPE';
    }

    getIcon(productType: ProductType): string {
        const type2icon: Record<ProductType, string> = {
            [ProductType.ACQUIRING_PREPAYMENT]: PapaImage.PRODUCT_ACQUIRING,
            [ProductType.MARKETPLACE_LOAN]: PapaImage.PRODUCT_MARKETPLACE,
            [ProductType.ONLINE_STORE_LOAN]: PapaImage.PRODUCT_ONLINE_STORE,
            [ProductType.TENDER_LOAN]: PapaImage.PRODUCT_TENDER,
            [ProductType.CONTRACT_LOAN]: PapaImage.PRODUCT_CONTRACT,
            [ProductType.CARGO_LOAN]: PapaImage.PRODUCT_CARGO,
            [ProductType.TURNOVER_LOAN]: PapaImage.PRODUCT_TURNOVER,
            [ProductType.FACTORING_LOAN]: PapaImage.PRODUCT_FACTORING,
            [ProductType.TRANSPORT_LEASING]: PapaImage.PRODUCT_TRANSPORT,
            [ProductType.EQUIPMENT_LEASING]: PapaImage.PRODUCT_EQUIPMENT,
            [ProductType.LOAN_UNDER_PTS]: PapaImage.PRODUCT_PTS,
            [ProductType.FRANCHISE_LOAN]: PapaImage.PRODUCT_FRANCHISE,
        };

        return type2icon[productType] || PapaImage.ROCKET;
    }

    /**
     * Возможна ли выписка для продукта
     */
    hasStatement(productType: ProductType): boolean {
        return productType !== ProductType.TENDER_LOAN;
    }
}
