import React from 'react';
import { createUseStyles } from 'react-jss';
import { Space, Text } from 'just-core-ui/components';
import { colorToTop, StyleService, Theme, useTheme } from 'just-core-ui';

const ICON_SIZE = 20;

type ProductCardProps = {
    title: string;
    icon?: React.ComponentType<{ color: string; size: number; }>;
    children?: React.ReactNode;
    onClick?: () => void;
    darkMode?: boolean;
};

const useStyles = createUseStyles({
    ProductCard: (props: ProductCardProps & { theme: Theme }) => ({
        border: `1px solid ${colorToTop(props.theme, 7)}`,
        padding: StyleService.instance.getSpace(props.theme, 'm'),
        borderRadius: StyleService.instance.getRadius(props.theme, 'l'),
        cursor: !!props.onClick ? 'pointer' : null,
    }),
    ProductCard__Title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export function ProductCard(props: ProductCardProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles({
        ...props,
        theme,
    });

    return (
        <div className={styles.ProductCard} onClick={props.onClick}>
            <div className={styles.ProductCard__Title}>
                <Text
                    weight={'medium'}
                    height={'s'}
                >
                    {props.title}
                </Text>
                {Boolean(props.icon) && React.createElement(props.icon, {
                    color: colorToTop(theme, 5),
                    size: ICON_SIZE,
                })}
            </div>
            <Space h={'s'}/>
            {props.children}
        </div>
    );
}
