import { Notification } from '../common';
import { notifySlice } from '../state';

const DEFAULT_SECONDS = 5;

let COUNTER = 0;

export type NotifyParams = Pick<Notification, 'title' | 'message' | 'type'> & {
    seconds?: number;
    code?: string;
};

export const NotifyActions = {
    notify: (params: NotifyParams) => async (dispatch) => {
        const id = COUNTER++;

        const {
            type,
            title,
            message,
            code,
            seconds = DEFAULT_SECONDS,
        } = params;

        const notification: Notification = {
            id,
            type,
            title,
            message,
            code,
        };

        dispatch(notifySlice.actions.add(notification));

        if (seconds !== null) {
            setTimeout(() => {
                dispatch(notifySlice.actions.del(id));
            }, seconds * 1000);
        }
    },
};
