import React from 'react';
import { SvgIcon } from '~/components';

export const CalendarSvg20 = ({ color, size = 20 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83325 10.8335H14.1666" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.83325 14.1667H14.1666" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.3333 1.66675V5.00008" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.66675 1.66675V5.00008" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.5 7.5H17.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M15.8333 3.3335H4.16667C3.24583 3.3335 2.5 4.07933 2.5 5.00016V15.8335C2.5 16.7543 3.24583 17.5002 4.16667 17.5002H15.8333C16.7542 17.5002 17.5 16.7543 17.5 15.8335V5.00016C17.5 4.07933 16.7542 3.3335 15.8333 3.3335Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);
