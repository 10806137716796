import { getCookie, setCookie } from '~/utils';
import { User } from './common';
import { SessionCheckResult } from '~/common/app-api';

export const getSessionKey = () => getCookie('papa_session_key');
export const setSessionKey = (value: string) => setCookie('papa_session_key', value);
// @todo перейти на customer key
export const getCustomerId = () => getCookie('papa_customer_id');
export const setCustomerId = (value: string) => setCookie('papa_customer_id', value);

export const createDataWithSessionKey = (data: object = {}) => ({
    ['session_key']: getSessionKey(),
    ...data,
});

export const parseUser = (data: SessionCheckResult): User => ({
    phone: String(data['phone']),
    email: String(data['email']),
    firstName: String(data['first_name']),
    midName: String(data['mid_name']),
    lastName: String(data['last_name']),
    customers: Array.isArray(data['customers']) ? data['customers'] : [],
    inns: Array.isArray(data['inns']) ? data['inns'] : [],
});
