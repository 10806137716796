import React from 'react';
import { Button, Link, Space, Text, ThemeService, Toggle, useTheme } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { setSessionKey, SessionSelectors } from '~/common/session';
import { ContainerPage, Heading, Wrapper } from '~/components';
import { ProfileRow } from './profile-row';
import { ProfileRowCluster } from './profile-row-cluster';
import { MainHeader } from '../../components/main-header';
import { MainFooter } from '../../components/main-footer';
import { ProfileCloseButton } from '../../components/profile-close-btn';

export function Profile(): JSX.Element {
    const theme = useTheme();
    const darkModeToggle = () => ThemeService.instance.toggleInvert();
    const jpmActions = useJustPageManagerActions();

    const user = SessionSelectors.user();
    const customer = SessionSelectors.customer();

    const logout = () => {
        setSessionKey('');
        location.reload();
    };

    const goToProfileChangeUser = () => jpmActions.pushLayer(
        'MODAL',
        'PROFILE_CHANGE_USER',
    );

    const goToProfileChangePhone = () => jpmActions.pushLayer(
        'MODAL',
        'PROFILE_CHANGE_PHONE',
    );

    const goToProfileChangePassword = () => jpmActions.pushLayer(
        'MODAL',
        'PROFILE_CHANGE_PASSWORD',
    );

    return (
        <ContainerPage>
					<ProfileCloseButton />
          <MainHeader />
            <Wrapper>
                <Text size={'3xl'} weight={'bold'} height={'s'}>
                    Профиль
                </Text>
            </Wrapper>
            <Wrapper>
                <Space h={'l'}/>
                <ProfileRow
                    title={'Организация'}
                    value={`«${customer.name}»`}
                    action={(
                        <Link disabled>
                            {/* <Link onClick={goToProfileChangeUser}> */}
                                {/* Изменить */}
                            {/* </Link> */}
                        </Link>
                    )}
                />
                <ProfileRow
                    title={'ИНН Организации'}
                    value={customer.inn}
                />
                <ProfileRow
                    title={'Представитель'}
										value={
											user.firstName !== 'null' && user.lastName !== 'null'
												? `${user.firstName} ${user.lastName}`
                    		: 'Не указано'
											}
                />
                <Space h={'l'}/>
            </Wrapper>
            <Heading>
                Контакты
            </Heading>
            <ProfileRowCluster>
                <ProfileRow
                    title={'Номер телефона'}
                    value={user.phone}
                    action={(
                        <Link disabled>
                            {/* <Link onClick={goToProfileChangePhone}> */}
                                {/* Изменить */}
                            {/* </Link> */}
                        </Link>
                    )}
                />
                <ProfileRow
                    title={'Электронная почта'}
                    value={
											user.email !== 'null'
												? `${user.email}`
												: 'E-mail не указан'
										}
                />
            </ProfileRowCluster>
            {/*<Heading>*/}
            {/*    Безопасность*/}
            {/*</Heading>*/}
            {/*<ProfileRowCluster>*/}
            {/*    <ProfileRow*/}
            {/*        title={'Пароль для входа'}*/}
            {/*        value={'Безопасный пароль'}*/}
            {/*        action={(*/}
            {/*            <Text color={theme.color['GRAPE']}>*/}
            {/*                <Link onClick={goToProfileChangePassword}>*/}
            {/*                    Изменить*/}
            {/*                </Link>*/}
            {/*            </Text>*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</ProfileRowCluster>*/}
            <Heading>
                Уведомления
            </Heading>
            {/*<ProfileRowCluster>
                <ProfileRow
                    title={'Уведомлять о платежах'}
                    value={'По электронной почте и смс'}
                />
                <ProfileRow
                    title={'Уведомлять о просрочке'}
                    value={'По электронной почте и смс'}
                />
                <ProfileRow
                    title={'Уведомлять о решении по заявке'}
                    value={'По смс'}
                />
            </ProfileRowCluster>*/}
            <Heading>
                Оформление
            </Heading>
            <ProfileRowCluster>
                <ProfileRow
                    title={'Темная тема'}
                    action={(
                        <Toggle
                            value={theme.invert}
                            onClick={darkModeToggle}
                        />
                    )}
                />
            </ProfileRowCluster>
            <Heading>
                Авторизация
            </Heading>
            <ProfileRowCluster>
                <Button onClick={logout}>
                    Выйти
                </Button>
            </ProfileRowCluster>
          <MainFooter />
        </ContainerPage>
    );
}
