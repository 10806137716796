import { combineReducers } from 'redux';
import { notifySlice } from '~/common/notify';
import { productSlice } from '~/common/product';
import { sessionSlice } from '~/common/session';

export const rootReducer = combineReducers({
    notify: notifySlice.reducer,
    product: productSlice.reducer,
    session: sessionSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
