import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorToTop, Space, StyleService, Text, Theme, useTheme } from 'just-core-ui';

type ItemGridElementProps = {
    title: string;
    description?: string;
    onClick?: () => void;
    color?: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    darkMode?: boolean;
};

const useStyles = createUseStyles({
    ItemGridElement: (props: ItemGridElementProps) => ({
        cursor: !props.disabled ? 'pointer' : null,
        transition: 'transform 0.5s ease',
        // '&:hover': {
        //     transform: 'scale(0.98)',
        // },
    }),
    ItemGridElement__Box: (props: ItemGridElementProps & { theme: Theme }) => ({
        height: 220,
        backgroundColor: props.disabled
            ? null
            : props.color || colorToTop(props.theme, { opacity: 0.05 }),
        boxShadow: props.disabled
            ? `inset 0 0 0 1px ${colorToTop(props.theme, 7)}`
            : '',
        borderRadius: StyleService.instance.getRadius(props.theme, 'm'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: props.disabled ? 0.5 : 1,
        '@media (max-width: 400px)': {
            height: 170,
        }
    }),
});

export function ItemGridElement(props: ItemGridElementProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles({
        ...props,
        theme,
    });

    return (
        <div
            className={styles.ItemGridElement}
            onClick={props.onClick}
        >
            <div className={styles.ItemGridElement__Box}>
                {props.icon}
            </div>
            <Space h={'s'}/>
            <Text
                weight={'medium'}
                height={'l'}
                color={colorToTop(theme, props.disabled ? 5 : 0)}
            >
                {props.title}
            </Text>
            {props.description && (
                <>
                    <Space h={'xs'}/>
                    <Text
                        size={'s'}
                        color={colorToTop(theme, 5)}
                    >
                        {props.description}
                    </Text>
                </>
            )}
        </div>
    );
}
