import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorToTop, Link, Space, StyleService, Text, useTheme } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { Wrapper } from '~/components';
import { AvatarSvg64 } from '~/common/icons';

export type UserHeaderProps = {
    name: string;
};

const useStyles = createUseStyles({
    UserHeader: {},
    UserHeader__Inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    UserHeader__Left: {},
    UserHeader__Right: {},
});

export function UserHeader(props: UserHeaderProps): JSX.Element {
    const theme = useTheme();

    const jpmActions = useJustPageManagerActions();

    const goToProfile = () => jpmActions.pushLayer('BASE', 'PROFILE');

    const styles = useStyles();

    return (
        <Wrapper>
            <div className={styles.UserHeader}>
                <div className={styles.UserHeader__Inner}>
                    <div className={styles.UserHeader__Left}>
                        <Text size={'3xl'} weight={'bold'} height={'s'}>
                            {props.name}
                        </Text>
                        <Space h={'s'}/>
                        <Text weight={'medium'} height={'s'}>
                            <Link onClick={goToProfile}>
                                Профиль и настройки
                            </Link>
                        </Text>
                    </div>
                    <div className={styles.UserHeader__Right}>
                        <AvatarSvg64
                            color={colorToTop(theme, 7)}
                            colorSecondary={colorToTop(theme, 4)}
                        />
                    </div>
                </div>
                <Space h={'l'}/>
            </div>
        </Wrapper>
    );
}
