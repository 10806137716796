import React from 'react';
import { Cell, Link, Space, Text } from 'just-core-ui';
import { CalendarSvg20 } from '~/common/icons';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { MoneyProgress, Wrapper } from '~/components';
import { Loan, MonthProgress, ScheduledPayment } from '../common';
import { ProductCard } from './product-card';
import { ProductCardRow } from './product-card-row';

const month = [
    '',
    'январе',
    'феврале',
    'марте',
    'апреле',
    'мае',
    'июне',
    'июле',
    'августе',
    'сентябре',
    'октябре',
    'ноябре',
    'декабре',
];

export type ProductCardPaymentProgressProps = {
    scheduledPayments: Array<ScheduledPayment>,
    monthProgress: MonthProgress;
    loan: Loan;
};

export function ProductCardPaymentProgress(props: ProductCardPaymentProgressProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const handleMoreClick = () => jpmActions.pushLayer(
        'MODAL',
        'PRODUCT_SCHEDULED_PAYMENTS',
        {
            scheduledPayments: props.scheduledPayments,
            loan: props.loan,
        },
    );

    return (
        <Wrapper>
            <ProductCard
                title={`Погашение в ${month[props.monthProgress.monthNumber]}`}
                icon={CalendarSvg20}
            >
                <Space h={'m'}/>
                <MoneyProgress
                    already={props.monthProgress.paidAmount}
                    total={props.monthProgress.totalAmount}
                    progress={props.monthProgress.progressPercent}
                />
                <Space h={'l'}/>
                <ProductCardRow
                    title={'Процент удержания в день:'}
                    value={`${props.monthProgress.dayPercent} %`}
                />
                <Space h={'xs'}/>
                <Cell
                    paddingY={'xs'}
                >
                    <Text
                        size={'s'}
                        height={'s'}
                    >
                        <Link onClick={handleMoreClick}>
                            Все списания
                        </Link>
                    </Text>
                </Cell>
            </ProductCard>
        </Wrapper>
    );
}
