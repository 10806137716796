import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorToBot, StyleService, Theme, useTheme } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { BackSvg24, CloseSvg24 } from '~/common/icons';

const HEADER_HEIGHT = 40;

export type JpmLayoutRightProps = {
    pages: Array<any>;
    render: (page: any) => React.ReactNode;
    hidePopLayerButton?: boolean;
    isTopLayer?: boolean;
    darkMode?: boolean;
};

const useStyles = createUseStyles({
    JpmLayoutRight: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    JpmLayoutRight__Bg: (props: JpmLayoutRightProps & { theme: Theme }) => ({
        backgroundColor: props.isTopLayer
            ? colorToBot(props.theme, { opacity: 0.8 }, '#000000')
            : null,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        cursor: 'pointer',
    }),
    JpmLayoutRight__Window: (props: JpmLayoutRightProps & { theme: Theme }) => ({
        backgroundColor: StyleService.instance.getTopColor(props.theme),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '@media (min-width: 601px)': {
            left: 'auto',
            right: 0,
            width: 460,
        },
    }),
    JpmLayoutRight__Head: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: HEADER_HEIGHT,
    },
    JpmLayoutRight__Head_Left: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
    },
    JpmLayoutRight__Head_Right: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
    },
    JpmLayoutRight__Body: {
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        bottom: 0,
    },
    JpmLayoutRight__Body_Content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        overflowY: 'auto',
        display: 'none',
        '&:last-child': {
            display: 'block',
        },
    },
    JpmLayoutRight__Button: {
        width: HEADER_HEIGHT,
        height: HEADER_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
});

export function JpmLayoutRight(props: JpmLayoutRightProps): JSX.Element {
    const theme = useTheme();

    const actions = useJustPageManagerActions();

    const styles = useStyles({
        ...props,
        theme,
    });

    return (
        <div className={styles.JpmLayoutRight}>
            <div
                className={styles.JpmLayoutRight__Bg}
                onClick={actions.popLayer}
            />
            <div className={styles.JpmLayoutRight__Window}>
                <div className={styles.JpmLayoutRight__Body}>
                    {props.pages.map((page, index) => (
                        <div className={styles.JpmLayoutRight__Body_Content} key={index}>
                            {props.render(page)}
                        </div>
                    ))}
                </div>
                <div className={styles.JpmLayoutRight__Head}>
                    <div className={styles.JpmLayoutRight__Head_Left}>
                        {props.pages.length > 1 && (
                            <div
                                className={styles.JpmLayoutRight__Button}
                                onClick={actions.popPage}
                            >
                                <BackSvg24 color={StyleService.instance.getBotColor(theme)}/>
                            </div>
                        )}
                    </div>
                    <div className={styles.JpmLayoutRight__Head_Right}>
                        {!props.hidePopLayerButton && (
                            <div
                                className={styles.JpmLayoutRight__Button}
                                onClick={actions.popLayer}
                            >
                                <CloseSvg24 color={StyleService.instance.getBotColor(theme)}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
