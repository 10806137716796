export enum PapaImage {
    LOGO = '/static/images/logo.svg',
    MAILBOX = '/static/images/mailbox.png',
    PRODUCT_ACQUIRING = '/static/images/product-acquiring.svg',
    PRODUCT_CARGO = '/static/images/product-cargo.svg',
    PRODUCT_CONTRACT = '/static/images/product-contract.svg',
    PRODUCT_MARKETPLACE = '/static/images/product-marketplace.svg',
    PRODUCT_ONLINE_STORE = '/static/images/product-online-store.svg',
    PRODUCT_TENDER = '/static/images/product-tender.svg',
    PRODUCT_TURNOVER = '/static/images/product-turnover.svg',
    PRODUCT_FACTORING = '/static/images/product-factoring.svg',
    PRODUCT_TRANSPORT = '/static/images/product-transport.svg',
    PRODUCT_EQUIPMENT = '/static/images/product-equipment.svg',
    PRODUCT_PTS = '/static/images/product-pts.png',
    PRODUCT_FRANCHISE = '/static/images/product-franchise.svg',
    ROCKET = '/static/images/rocket.png',
    USEFUL_CART = '/static/images/useful-cart.svg',
    USEFUL_CHAT = '/static/images/useful-chat.svg',
    USEFUL_RATING = '/static/images/useful-rating.svg',
    TELEGRAM_LOGO = '/static/images/tg.svg',
    INSTAGRAM_LOGO = '/static/images/inst.svg',
    VK_LOGO = '/static/images/vk.svg',
    FACEBOOK_LOGO = '/static/images/fb.svg',
    BANK_RUSSIA = '/static/images/russia.svg',
    MIR = '/static/images/mir.svg',
}
