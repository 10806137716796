import React from 'react';
import { Cell, Text } from 'just-core-ui';

type CardRowProps = {
    title: string;
    value: React.ReactNode;
};

export function ProductCardRow(props: CardRowProps): JSX.Element {
    return (
        <Cell
            paddingY={'xs'}
            spaceBetween
        >
            <Text
                size={'s'}
                height={'s'}
                key={props.title}
                tag={'div'}
            >
                {props.title}
            </Text>
            <Text
                size={'s'}
                weight={'medium'}
                height={'s'}
                tag={'div'}
            >
                {props.value}
            </Text>
        </Cell>
    );
}
