import {
    Home,
    Product,
    ProductAbout,
    ProductArchive,
    ProductNew,
    ProductOperations,
    ProductPay,
    ProductScheduledPayments,
    ProductStatement,
    Profile,
} from '~/pages';

export const JpmPages: Record<string, any> = {
    HOME: Home,
    PRODUCT: Product,
    PRODUCT_ABOUT: ProductAbout,
    PRODUCT_ARCHIVE: ProductArchive,
    PRODUCT_NEW: ProductNew,
    PRODUCT_OPERATIONS: ProductOperations,
    PRODUCT_PAY: ProductPay,
    PRODUCT_SCHEDULED_PAYMENTS: ProductScheduledPayments,
    PRODUCT_STATEMENT: ProductStatement,
    PROFILE: Profile,
};
