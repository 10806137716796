import React, { FC, useEffect } from "react";

declare global {
    interface Window {
        ym: any
    }
}

const ymApiKey: string = process.env.YM_API_KEY;

const Ym: FC = () => {
    useEffect(() => {

        window.ym = window.ym || function () {
            (window.ym.a = window.ym.a || []).push(arguments)
        };
        // @ts-ignore
        window.ym.l = 1 * new Date();
        const script = document.createElement('script');
        script.async = true;
        script.src = "https://mc.yandex.ru/metrika/tag.js";
        document.body.appendChild(script),
            script.onload = () => {

                window.ym(ymApiKey, "init", {
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true
                })
            }

        return () => {
            document.body.removeChild(script);
        };



    }, [])


    return (
        <>
        </>
    );
};
export default Ym;
