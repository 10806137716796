import { getMonth, format } from 'date-fns';
import { Loan, MonthProgress, ProductType, ScheduledPayment, ScheduleSlice } from '../common';
import { LoanRepository } from '../repositories';
import { mapLoan } from '~/common/product';
import { getSessionKey } from '~/common/session';
import { LoanRequestJson } from '~/common/app-api';

export class LoanService {
    static readonly instance = new LoanService();

    async getLoans(customerId: number): Promise<Array<Loan>> {
        const appLoans = await LoanRepository.instance.appLoanGetList({
            customer_id: customerId,
            session_key: getSessionKey(),
        });

        return appLoans.map(mapLoan);
    }

    async createLoanRequest(customerId: number, type: string, amount: number): Promise<LoanRequestJson> {
        const loanRequest = await LoanRepository.instance.loanRequestCreate({
            customer_id: customerId,
            session_key: getSessionKey(),
            type,
            amount,
        });

        return loanRequest || null;
    }

    async getLoanRequests(customerId: number): Promise<Array<LoanRequestJson>> {
        const loanRequests = await LoanRepository.instance.loanRequestGetList({
            customer_id: customerId,
            session_key: getSessionKey(),
        });

        return loanRequests || [];
    }

    getMonthProgress(loan: Loan, date: Date, scheduledPayments: Array<ScheduledPayment>): MonthProgress {
        const hasMonthProgress = [
            ProductType.ACQUIRING_PREPAYMENT,
            ProductType.ONLINE_STORE_LOAN,
        ].includes(loan.productType);

        if (hasMonthProgress && scheduledPayments.length) {
            const monthNumber = format(getMonth(date), 'M');

            const monthlyScheduledPayments = scheduledPayments.filter(
                scheduledPayment => format(getMonth(scheduledPayment.initPayDate), 'M') === monthNumber,
            );

            const { totalAmount, paidAmount } = monthlyScheduledPayments.reduce(
                (data, scheduledPayment) => ({
                    totalAmount: data.totalAmount + Number(scheduledPayment.initTotalAmount),
                    paidAmount: data.paidAmount + Number(scheduledPayment.currentTotalAmount),
                }),
                {
                    totalAmount: 0,
                    paidAmount: 0,
                },
            );

            const restAmount = totalAmount - paidAmount;

            const progressPercent = paidAmount / totalAmount;

            const dayPercent = 999999;

            return {
                monthNumber: parseInt(monthNumber),
                totalAmount: String(totalAmount),
                restAmount: String(restAmount),
                paidAmount: String(paidAmount),
                progressPercent,
                dayPercent,
            };
        }

        return null;
    }

    getScheduleSlice(loan: Loan, scheduledPayments: Array<ScheduledPayment>): ScheduleSlice {
        const hasMonthProgress = [
            ProductType.MARKETPLACE_LOAN,
            ProductType.CONTRACT_LOAN,
            ProductType.CARGO_LOAN,
            ProductType.TURNOVER_LOAN,
        ].includes(loan.productType);

        if (hasMonthProgress && scheduledPayments.length) {
            const emptyScheduledPayments = scheduledPayments.filter(
                scheduledPayment => !Number(scheduledPayment.currentTotalAmount),
            ).sort(
                (a, b) => a.initNumber - b.initNumber,
            );

            const paymentsLeft = emptyScheduledPayments.length;

            const nextPaymentDate = emptyScheduledPayments.length ? emptyScheduledPayments[0].initPayDate : null;

            const nextPaymentAmount = emptyScheduledPayments.length ? emptyScheduledPayments[0].initTotalAmount : null;

            return {
                paymentsLeft,
                nextPaymentDate,
                nextPaymentAmount,
            };
        }

        return null;
    }
}
