import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './root';

export function configureStore() {
    const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

    return createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(thunk),
        )
    );
}
