import { AppPaymentJson } from '~/common/app-api';
import { Payment } from '../common';

export function mapPayment(data: AppPaymentJson): Payment {
    return {
        amount: String(data.amount),
        reason: String(data.reason),
        date: new Date(data.date),
    };
}
