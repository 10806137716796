import { useSelector } from 'react-redux';
import { RootState } from '~/redux/root';
import { Payment, ScheduledPayment } from '../common';
import { ProductState } from './product-state';

export const ProductSelector = {
    loans: () => useSelector<RootState, ProductState['loans']>(state => state.product.loans),
    loansActive: () => useSelector<RootState, ProductState['loans']>(state => state.product.loans.filter(loan => loan.active)),
    loansArchive: () => useSelector<RootState, ProductState['loans']>(state => state.product.loans.filter(loan => !loan.active)),
    loansLoading: () => useSelector<RootState, ProductState['loansLoading']>(state => state.product.loansLoading),

    loanRequests: () => useSelector<RootState, ProductState['loanRequests']>(state => state.product.loanRequests),
    loanRequestsLoading: () => useSelector<RootState, ProductState['loanRequestsLoading']>(state => state.product.loanRequestsLoading),

    paymentsByLoan: (loanKey: string) => useSelector<RootState, Array<Payment>>(state => state.product.paymentsRecord[loanKey] || []),

    scheduledPaymentsByLoan: (loanKey: string) => useSelector<RootState, Array<ScheduledPayment>>(state => state.product.scheduledPaymentsRecord[loanKey] || []),
};
