import React from 'react';
import { Space, Text } from 'just-core-ui';
import { SessionSelectors } from '~/common/session';
import { TextSessionDescription, TextSessionTitle } from '~/components';
import { SessionLayout } from './session-layout';

export function SessionPageInnProcess(): JSX.Element {
    const user = SessionSelectors.user();

    return (
        <SessionLayout>
            <Space h={'l'}/>
            <TextSessionTitle>
                Организации на рассмотрении
            </TextSessionTitle>
            <Space h={'s'}/>
            <TextSessionDescription>
                Скоро с вами свяжутся для подтверждения прав.
            </TextSessionDescription>
            <Space h={'2xl'}/>
            {!!user.inns.length && (
                <ul>
                    {user.inns.map(inn => (
                        <li key={inn}>
                            <Text>
                                {inn}
                            </Text>
                        </li>
                    ))}
                </ul>
            )}
        </SessionLayout>
    );
}
