import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorToTop, useTheme } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { BackSvg24 } from '~/common/icons';

const useStyles = createUseStyles({
    close__Button: {
			display: 'flex',
			justifyContent: 'center',
      alignItems: 'center',
			position: 'fixed',
			top: '85px',
			marginLeft: '-45px',
      width: '40px',
      height: '40px',
      cursor: 'pointer',

			'@media screen and (max-width: 950px)': {
				position: 'absolute',
				right: '40px',
				marginLeft: '0px',
			},
		},
});

export function ProfileCloseButton(): JSX.Element {
    const theme = useTheme();
    const actions = useJustPageManagerActions();
    const styles = useStyles();

    return (
      <div onClick={actions.popLayer} className={styles.close__Button}>
        <BackSvg24
          color={colorToTop(theme, 2)}
        />
			</div>
    );
}
