import React from 'react';
import { Text } from 'just-core-ui';

export type TextListTitleProps = {
    children: React.ReactNode;
};

export function TextListTitle(props: TextListTitleProps): JSX.Element {
    return (
        <Text
            size={'s'}
            weight={'medium'}
            height={'l'}
        >
            {props.children}
        </Text>
    );
}
