import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorToTop, Space, Text, Theme, useTheme } from 'just-core-ui';
import { Money } from '~/components';

export type SessionCustomerRowProps = {
    title: string;
    inn: string;
    onClick: () => void;
    noTopBorder?: boolean;
};

const useStyles = createUseStyles({
    SessionCustomerRow: {
        cursor: 'pointer',
        borderTop: (props: SessionCustomerRowProps & { theme: Theme }) =>
            props.noTopBorder
                ? null
                : `1px solid ${colorToTop(props.theme, 7)}`,
    },
    SessionCustomerRow__Flex: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

export function SessionCustomerRow(props: SessionCustomerRowProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles({
        ...props,
        theme,
    });

    return (
        <div className={styles.SessionCustomerRow} onClick={props.onClick}>
            <Space h={'s'}/>
            <div>
                <Text weight={'medium'}>
                    {props.title}
                </Text>
                <div className={styles.SessionCustomerRow__Flex}>
                <Text size={'m'}>
                    {'ИНН организации'}
                </Text>
                <Text size={'m'}>
                    {props.inn}
                </Text>
                </div>
            </div>
            <Space h={'s'}/>
        </div>
    );
}
