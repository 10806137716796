import axios from 'axios';
import { JsonRpcResponseError } from './json-rpc-response-error';

export type JsonRpcClientOptions = {
    addMethodToUrl?: boolean;
    dontCheckMetaData?: boolean;
};

export class JsonRpcClient {
    private readonly url: string;
    private readonly addMethodToUrl?: boolean;
    private readonly dontCheckMetaData?: boolean;

    constructor(url: string, options?: JsonRpcClientOptions) {
        this.url = url;

        if (options) {
            this.addMethodToUrl = options.addMethodToUrl;
            this.dontCheckMetaData = options.dontCheckMetaData;
        }
    }

    private getMethodUrl(method: string): string {
        return this.addMethodToUrl ? `${this.url}/${method}` : this.url;
    }

    async call<PARAMS, RESULT>(method: string, params?: PARAMS): Promise<RESULT> {
        const randomString = String(Math.random());

        const data = {
            jsonrpc: '2.0',
            method,
            params,
            id: randomString,
        };

        const response = await axios.post(
            this.getMethodUrl(method),
            data,
        );

        const { jsonrpc, result, error, id } = response.data;

        if (!this.dontCheckMetaData) {
            if (jsonrpc !== '2.0' || id !== randomString) {
                throw new JsonRpcResponseError('Internal error', -32603);
            }
        }

        if (error) {
            throw new JsonRpcResponseError(error.message, error.code);
        }

        if (typeof result === 'undefined') {
            throw new JsonRpcResponseError('No result');
        }

        return result;
    }

    async notify<PARAMS>(method: string, params?: PARAMS): Promise<void> {
        const data = {
            jsonrpc: '2.0',
            method,
            params,
        };

        const response = await axios.post(
            this.getMethodUrl(method),
            data,
        );

        const { result, error } = response.data;

        if (error) {
            throw new JsonRpcResponseError(error.message, error.code);
        }

        return result;
    }
}
