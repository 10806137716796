import React, { useMemo } from 'react';
import { colorToTop, Space, StyleService, Text, Theme, useTheme } from 'just-core-ui';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { ru as locale } from 'date-fns/locale';
import { Payment, PaymentService } from '~/common/product';
import { ModalHeader, Money, TextListTitle, Wrapper } from '~/components';

export type ProductOperationsProps = {
    payments: Array<Payment>;
};

const useStyles = createUseStyles({
    ProductOperations__ListItem: {
        display: 'flex',
        alignItems: 'center',
    },
    ProductOperations__Circle: {
        flexBasis: 40,
    },
    ProductOperations__CircleRound: (theme: Theme) => ({
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: colorToTop(theme, 7),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    ProductOperations__CircleRoundFull: (theme: Theme) => ({
        width: 40,
        height: 40,
        borderRadius: 40,
        backgroundColor: colorToTop(theme, 2, StyleService.instance.getColor(theme, 'GREEN')),
    }),
    ProductOperations__Content: {
        marginLeft: 20,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export function ProductOperations(props: ProductOperationsProps): JSX.Element {
    const theme = useTheme();

    const titledPayments = useMemo(
        () => PaymentService.instance.makeTitledList(props.payments),
        [props.payments],
    );
    const paymentsInfo = useMemo(
        () => PaymentService.instance.getSummary(props.payments),
        [props.payments],
    );

    const styles = useStyles(theme);

    return (
        <div>
            <ModalHeader
                title={'Операции'}
                description={
                  paymentsInfo.amount
                    ? `Общая сумма платежей ${(parseFloat(paymentsInfo.amount)).toLocaleString('ru')} ₽`
                    : 'Нет зачисленных платежей'
                }
            />
            {titledPayments.map(titledList => (
                <React.Fragment key={titledList.title}>
                    <Wrapper space={'l'}>
                        <Space h={'s'}/>
                        <TextListTitle>
                            {titledList.title}
                        </TextListTitle>
                        <Space h={'s'}/>
                    </Wrapper>
                    {titledList.list.map((payment, index) => (
                        <Wrapper
                            key={index}
                            space={'l'}
                        >
                            <Space h={'s'}/>
                            <div className={styles.ProductOperations__ListItem}>
                                <div className={styles.ProductOperations__Circle}>
                                    <div className={styles.ProductOperations__CircleRound}/>
                                </div>
                                <div className={styles.ProductOperations__Content}>
                                    <div>
                                        <Text>
                                            {format(payment.date, 'd MMMM', { locale })}
                                        </Text>
                                    </div>
                                    <div>
                                        <Text
                                            color={StyleService.instance.getColor(theme, 'GREEN')}
                                        >
                                            + <Money amount={payment.amount}/>
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <Space h={'s'}/>
                        </Wrapper>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}
