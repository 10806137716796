import React from 'react';
import { SvgIcon } from '~/components';

export const RubleSvg20 = ({ color, size = 20 }) => (
    <SvgIcon size={size}>
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.16259 13.75V6.25H11.3109C12.4176 6.25 13.3151 7.08917 13.3151 8.125C13.3151 9.16083 12.4176 10 11.3109 10M11.3084 10H6.66675M10.8334 12.2917H6.66675M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);
