import React from 'react';
import { JustPageManagerPage } from '../common';

export type JustPageManagerPageViewProps = {
    pages: Record<string, React.FC<any>>;
    page: JustPageManagerPage;
};

export function JustPageManagerPageView(props: JustPageManagerPageViewProps): JSX.Element {
    const Page = props.pages[props.page.key];

    if (Page) {
        return (
            <Page {...props.page.props}/>
        );
    }
}
