import { useSelector } from 'react-redux';
import { RootState } from '~/redux/root';
import { SessionState } from './session-state';

export const SessionSelectors = {
    user: () => useSelector<RootState, SessionState['user']>(state => state.session.user),
    customer: () => useSelector<RootState, SessionState['customer']>(state => state.session.customer),
    checking: () => useSelector<RootState, SessionState['checking']>(state => state.session.checking),

    authToken: () => useSelector<RootState, SessionState['authToken']>(state => state.session.authToken),
    authTokenCreatedAt: () => useSelector<RootState, SessionState['authTokenCreatedAt']>(state => state.session.authTokenCreatedAt),
    authTokenLoading: () => useSelector<RootState, SessionState['authTokenLoading']>(state => state.session.authTokenLoading),

    authKeyCount: () => useSelector<RootState, SessionState['authKeyCount']>(state => state.session.authKeyCount),
    authKeyLoading: () => useSelector<RootState, SessionState['authKeyLoading']>(state => state.session.authKeyLoading),

    innRequestCount: () => useSelector<RootState, SessionState['innRequestCount']>(state => state.session.innRequestCount),
    innRequestLoading: () => useSelector<RootState, SessionState['innRequestLoading']>(state => state.session.innRequestLoading),
};
