export function GetScheduledPaymentsMessage(overdue_days: number): string {
  switch (overdue_days) {
    case 0:
      return "Молодцы! Вы на правильном пути! \u{1F3C6}"; //смайлик кубок
    case 1:
    case 2:
    case 3:
    case 4:
      return "Кажется, что-то пошло не так. /n Пожалуйста, погасите задолженность. \u{1F97A}"; // смайлик грустное лицо
    default:
      return "Хьюстон, у нас проблемы. \u{1F926} /n Давайте все исправим!"; //смайлик рукалицо
  }
}
