import React from 'react';
import { ContainerPage } from '~/components';
import { HomeProducts } from './home-products';
import { HomeRecommendations } from './home-recomendations';
import { HomeUseful } from './home-useful';
import { HomeUserHeader } from './home-user-header';
import { MainHeader } from '../../components/main-header';
import { MainFooter } from '../../components/main-footer';
import Chatbot from '~/components/chatbot';

export function Home(): JSX.Element {
    return (
        <ContainerPage>
            <Chatbot />
            <MainHeader />
            <HomeUserHeader/>
            <HomeProducts/>
            <HomeRecommendations/>
            <HomeUseful/>
            <MainFooter />
        </ContainerPage>
    );
}
