import React from 'react';
import { Avatar, Space, Text } from 'just-core-ui';
import { Money, Wrapper } from '~/components';

export type ProductHeaderViewProps = {
    restAmount: string;
    totalAmount?: string;
    avatarColor?: string;
    avatarIcon?: React.ReactNode;
    description: string;
};

export function ProductHeaderView(props: ProductHeaderViewProps): JSX.Element {
    return (
        <Wrapper>
            {/*<Space h={'m'}/>*/}
            <Avatar
                size={'xl'}
                borderRadius={'l'}
                color={props.avatarColor}
            >
                {props.avatarIcon}
            </Avatar>
            <Space h={'l'}/>
            <Text
                size={'3xl'}
                weight={'medium'}
                height={'m'}
                tag={'span'}
            >
                <Money amount={props.restAmount}/>
            </Text>
            {Boolean(props.totalAmount) && (
                <Text
                    size={'3xl'}
                    weight={'medium'}
                    height={'m'}
                    tag={'span'}
                >
                    <br/>
                    из
                    {' '}
                    <Money amount={props.totalAmount}/>
                </Text>
            )}
            <Space h={'xs'}/>
            <Text
                size={'s'}
                height={'s'}
            >
                {props.description}
            </Text>
            <Space h={'m'}/>
        </Wrapper>
    );
}
