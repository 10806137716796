import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link, colorToTop, Space, Text, useTheme } from 'just-core-ui';
import { PapaImage } from '~/common/papa-image';

const phoneNumber: string = process.env.PHONE_NUMBER;
const phoneNumberHTML: string = process.env.PHONE_NUMBER_HTML;
const phoneNumberAlt: string = process.env.PHONE_NUMBER_ALT;
const phoneNumberAltHTML: string = process.env.PHONE_NUMBER_ALT_HTML;
const PFSiteLink: string = process.env.SITE_LINK;
const FinombudsmanLink: string = process.env.FINOMBUDSMAN_LINK;
const PPFContactMail: string = process.env.PPF_CONTACT_MAIL;

const useStyles = createUseStyles({
  footer: {
    display: 'block',
    width: '100%',
    padding: '32px 0',
    margin: '0 auto',
  },
  footer__logo_block: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer__adress_block: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer__logo_link: {
    display: 'block',
  },
  footer__supports_block: {
    display: 'flex',
    flexDirection: 'column',
    margin: '4px 0 8px',
    gap: '12px',
  },
  footer__supports: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
  },
  footer__socials: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  footer__socials_link: {
    textDecoration: 'none',
    margin: '0 5px',
  },
  footer__link: {
    textDecoration: 'none',
  },
  footer__about_block: {
    display: 'flex',
  },
  footer__main_info_block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '800px',
  },
  footer__info_text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '64%',
    textAlign: 'justify',
  },
  footer__info_links_block: {
    display: 'flex',
    flexDirection: 'column',
    width: '35%vw',
    paddingLeft: '8px',
  },
  footer__bank_info: {
    display: 'flex',
    width: '100%',
  },
  banks_block1: {
    width: '45%',
  },
  banks_block2: {
    width: '55%',
  },
  svg_normal: {},
  svg_inverse: {
    mixBlendMode: 'difference',
    filter: 'invert(0.8)',
  },
  '@media (max-width: 600px)': {
    footer__adress_block: {
      flexDirection: 'column',
      gap: '36px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    footer__main_info_block: {
      flexDirection: 'column',
    },
    footer__info_text: {
      width: '100%',
      padding: '10px 0',
    },
    footer__info_links_block: {
      width: '100%',
      padding: '10px 0',
    },
    footer__bank_info: {
      flexDirection: 'column',
    },
    banks_block1: {
      width: '95%',
    },
    banks_block2: {
      width: '95%',
    },
  },
});

export function MainFooter(): JSX.Element {
  const styles = useStyles();
  const theme = useTheme();

  const styleForSVG = theme.invert
      ? styles.svg_inverse
      : styles.svg_normal;

  return (
      <footer className={styles.footer}>
        <Space h={'4xl'}/>
        <div className={styles.footer__adress_block}>
          <div className={styles.footer__logo_block}>
            <a href={PFSiteLink} target="_blank">
              <img src={PapaImage.LOGO} alt="LOGO" />
            </a>
            <div className={styles.footer__supports_block}>
              <Text size={'xs'} color={colorToTop(theme, 4)}>Под контролем:</Text>
              <div className={styles.footer__supports}>
                <a className={styles.footer__logo_link} href="#" target="_blank">
                  <img src={PapaImage.MIR} alt="МИР" />
                </a>
                <a className={styles.footer__logo_link} href="#" target="_blank">
                  <img className={styleForSVG} src={PapaImage.BANK_RUSSIA} alt="БАНК РОССИИ" />
                </a>
              </div>
            </div>
            <Text size={'xs'} color={colorToTop(theme, 4)}>©2022 «Папа Финанc»</Text>
            <Text size={'xs'} color={colorToTop(theme, 4)}>Все права защищены</Text>
          </div>
          <div>
            <Text size={'s'} color={colorToTop(theme, 2)}>
              Наши телефоны и адрес в Москве:
            </Text>
            <a className={styles.footer__link} href={`tel:${phoneNumberAlt}`}>
              <Text size={'s'} color={colorToTop(theme, 2)}>
                <Link>{phoneNumberAltHTML}</Link>
              </Text>
            </a>
            <a className={styles.footer__link} href={`tel:${phoneNumber}`}>
              <Text size={'s'} color={colorToTop(theme, 2)}>
                <Link>{phoneNumberHTML}</Link>
              </Text>
            </a>
            <Space h={'s'}/>
            <Text size={'s'} color={colorToTop(theme, 2)}>
              105082, г. Москва, пер. Спартаковский,
            </Text>
            <Text size={'s'} color={colorToTop(theme, 2)}>
              д. 2, стр. 1, пом. Н.П.14
            </Text>
            <Text size={'s'} color={colorToTop(theme, 2)}>
              Режим работы: пн-пт: 10.00-19.00 (Мск)
            </Text>
          </div>
          <div className={styles.footer__socials}>
            <a className={styles.footer__socials_link} href="https://t.me/papafinanceru">
              <img className={styleForSVG} src={PapaImage.TELEGRAM_LOGO} alt="Telegram" />
            </a>
            <a className={styles.footer__socials_link} href="https://www.instagram.com/papafinanceru/">
              <img className={styleForSVG} src={PapaImage.INSTAGRAM_LOGO} alt="Instagram" />
            </a>
            <a className={styles.footer__socials_link} href="https://vk.com/papafinance">
              <img className={styleForSVG} src={PapaImage.VK_LOGO} alt="VKontakte" />
            </a>
            <a className={styles.footer__socials_link} href="https://www.facebook.com/papafinance">
              <img className={styleForSVG} src={PapaImage.FACEBOOK_LOGO} alt="Facebook" />
            </a>
          </div>
        </div>
        <Space h={'m'}/>
        <div className={styles.footer__about_block}>
          <div className={styles.footer__main_info_block}>
            <div className={styles.footer__info_text}>
              <Text size={'xs'} color={colorToTop(theme, 4)}>
                ООО МКК «Папа Финанс». Регистрационный номер записи в реестре микрофинансовых организаций 1903045009173 от 11.03.2019, ОГРН 1197746027742, ИНН 9701125685, КПП 770101001, Корреспондентский счет 30101810100000000787, БИК: 044525787, Юридический адрес: 105082, г. Москва, пер. Спартаковский, д. 2, стр. 1, пом. Н.П.14 Вводя номер телефона и заказывая звонок, Вы соглашаетесь на обработку Ваших персональных данных ООО МКК «Папа Финанс» (Общество с ограниченной ответственностью Микрокредитная компания «Папа Финанс»)
              </Text>
              <Space h={'m'}/>
              <div className={styles.footer__bank_info}>
                <div className={styles.banks_block1}>
                  <Text size={'xs'} color={colorToTop(theme, 4)}>
                    Дата внесения в реестр
                    {' '}
                    <a className={styles.footer__link} href="#">
                      <Link>
                        микрофинансовых организаций
                      </Link>
                      {' '}
                    </a>
                    11.03.2019
                  </Text>
                  <Text size={'xs'} color={colorToTop(theme, 4)}>Регистрационный номер записи 1197746027742</Text>
                  <Text size={'xs'} color={colorToTop(theme, 4)}>
                    <a className={styles.footer__link} href="#">
                      <Link>
                        Сайт Банка России
                      </Link>
                    </a>
                  </Text>
                  <Text size={'xs'} color={colorToTop(theme, 4)}>
                    <a className={styles.footer__link} href="#">
                      <Link>
                        Интернет-приемная Банка России
                      </Link>
                    </a>
                  </Text>
                </div>
                <div className={styles.banks_block2}>
                  <Text size={'xs'} color={colorToTop(theme, 4)}>
                    Вы можете обратиться в Службу финансового уполномоченного: 8 (800) 200-00-10
                    <a className={styles.footer__link} href={`${FinombudsmanLink}/`}>
                      {' '}
                      <Link>
                        {`${FinombudsmanLink}/`}
                      </Link>
                    </a>
                  </Text>
                  <Space h={'s'}/>
                  <Text size={'xs'} color={colorToTop(theme, 4)}>
                    119017, г. Москва, Старомонетный пер., д.3 или направить нам
                    <a className={styles.footer__link} href={`mailto:${PPFContactMail}`} target="_blank">
                      {' '}
                      <Link>
                        заявление
                      </Link>
                      {' '}
                    </a>
                    на оспаривание своей кредитной истории в Папа Финанс
                  </Text>
                </div>
              </div>
            </div>
            <div className={styles.footer__info_links_block}>
              <a className={styles.footer__link} href="https://papafinance.ru/assets/files/SRO.pdf" target="_blank">
                <Link>Свидетельство CРО ↗</Link>
              </a>
              <a className={styles.footer__link} href="https://papafinance.ru/assets/files/certificate.pdf" target="_blank">
                <Link>Свидетельство ЦБ ↗</Link>
              </a>
              <a className={styles.footer__link} href="https://papafinance.ru/assets/files/confidential.pdf" target="_blank">
                <Link>Политика конфиденциальности ↗</Link>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
}
