import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../common';
import { NotifyState } from './notify-state';

const initialState: NotifyState = {
    notifications: [],
};

export const notifySlice = createSlice({
    name: 'notify',
    initialState,
    reducers: {
        add: (state: NotifyState, action: PayloadAction<Notification>) => {
            state.notifications.push(action.payload);
        },
        del: (state: NotifyState, action: PayloadAction<Notification['id']>) => {
            const index = state.notifications.findIndex(notification => notification.id === action.payload);

            if (index >= 0) {
                state.notifications.splice(index, 1);
            }
        },
        clear: (state: NotifyState) => {
            state.notifications = [];
        },
    },
});
