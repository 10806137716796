import React, { useEffect, useMemo } from 'react';
import { Gapper, Space } from 'just-core-ui';
import {
    Loan,
    LoanService,
    ScheduledPayment,
    MonthProgress,
    PaymentService, ProductActions,
    ProductActionsPanel,
    ProductCardAbout,
    ProductCardOperations,
    ProductCardPaymentProgress,
    ProductCardPaymentSchedule,
    ProductHeaderView,
    ProductSelector,
    ProductTypeService,
} from '~/common/product';
import { ContainerPage } from '~/components';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { useActions } from '~/hooks';
import { SessionSelectors } from '~/common/session';

export type ProductProps = {
    loan: Loan;
    scheduledPayments: ScheduledPayment[];
    monthProgress: MonthProgress;
};

export function Product(props: ProductProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const productActions = useActions(ProductActions);

    const { loan } = props;

    const customer = SessionSelectors.customer();

    const payments = ProductSelector.paymentsByLoan(loan.key);

    const scheduledPayments = ProductSelector.scheduledPaymentsByLoan(loan.key);

    const paymentSummary = useMemo(
        () => PaymentService.instance.getSummary(payments),
        [payments],
    );

    // @todo использовать текущую дату с сервера
    const monthProgress = useMemo(
        () => LoanService.instance.getMonthProgress(loan, new Date(), scheduledPayments),
        [loan, scheduledPayments],
    );

    const scheduleSlice = useMemo(
        () => LoanService.instance.getScheduleSlice(loan, scheduledPayments),
        [loan, scheduledPayments],
    );

    const openOperations = () => jpmActions.pushLayer(
        'MODAL',
        'PRODUCT_OPERATIONS',
        {
            payments: payments,
        },
    );

    useEffect(() => {
        if (!payments.length) {
            productActions.getPaymentList(customer.id, loan.key);
        }

        if (!scheduledPayments.length) {
            productActions.getScheduledPaymentList(customer.id, loan.key);
        }
    }, [customer, loan]);

    const cards: Array<React.ReactNode> = [
        <ProductCardAbout data={loan}/>,
        monthProgress && <ProductCardPaymentProgress
            scheduledPayments={scheduledPayments}
            monthProgress={monthProgress}
            loan={loan}
        />,
        scheduleSlice && <ProductCardPaymentSchedule
            scheduledPayments={scheduledPayments}
            scheduleSlice={scheduleSlice}
            loan={loan}
        />,
        paymentSummary && <ProductCardOperations
            paymentSummary={paymentSummary}
            onClick={openOperations}
        />,
    ].filter(Boolean);

    return (
        <ContainerPage>
            <ProductHeaderView
                totalAmount={loan.initTotalAmount}
                restAmount={loan.currentTotalAmount}
                description={ProductTypeService.instance.getTitle(loan.productType)}
            />
            <ProductActionsPanel loan={loan}/>
            <Space h={'xs'}/>
            <Gapper step={'m'} children={cards}/>
            <Space h={'l'}/>
        </ContainerPage>
    );
}
