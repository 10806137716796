import { JsonRpcClient } from '~/common/just-json-rpc-client';
import { AppPaymentGetListParams, AppPaymentGetListResult } from '~/common/app-api';

export class PaymentRepository {
    static readonly instance = new PaymentRepository();

    private jsonRpcClient: JsonRpcClient;

    constructor() {
        this.jsonRpcClient = new JsonRpcClient('/api', {
            addMethodToUrl: true,
        });
    }

    async appPaymentGetList(params: AppPaymentGetListParams): Promise<AppPaymentGetListResult> {
        return this.jsonRpcClient.call('app-payment-get-list', params);
    }
}
