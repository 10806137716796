import React from 'react';
import { Space } from 'just-core-ui';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { PapaImage } from '~/common/papa-image';
import { ProductType } from '~/common/product';
import { Heading, ItemGrid, ItemGridElement } from '~/components';

export function HomeRecommendations(): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const handleItemClick = (type: ProductType) => () => jpmActions.pushLayer(
        'MODAL',
        'PRODUCT_ABOUT',
        {
            type,
        },
    );

    return (
        <>
            <Heading>
                Продукты
            </Heading>
            <ItemGrid>
                <ItemGridElement
                    title={'Для поставщиков'}
                    description={'до 10 млн. финансирование под оборот на Ozon и Wildberries'}
                    onClick={handleItemClick(ProductType.MARKETPLACE_LOAN)}
                    // color={tc.getColor('GREEN', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_MARKETPLACE}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Торговый эквайринг'}
                    description={'до 3 млн. финансирование под оборот торгового эквайринга'}
                    onClick={handleItemClick(ProductType.ACQUIRING_PREPAYMENT)}
                    // color={tc.getColor('LAVENDER', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_ACQUIRING}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Интернет-эквайринг'}
                    description={'до 3 млн. финансирование под оборот интернет-эквайринга'}
                    onClick={handleItemClick(ProductType.ONLINE_STORE_LOAN)}
                    // color={tc.getColor('MINT', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_ONLINE_STORE}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Исполнение контракта'}
                    description={'до 5 млн. финансирование сделок в соответствии с 44-ФЗ, 223-ФЗ'}
                    onClick={handleItemClick(ProductType.CONTRACT_LOAN)}
                    // color={tc.getColor('LIGHT_BLUE', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_CONTRACT}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Грузоперевозки'}
                    description={'до 3 млн. финансирование участников ATI'}
                    onClick={handleItemClick(ProductType.CARGO_LOAN)}
                    // color={tc.getColor('SALMON', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_CARGO}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Участие в тендерах'}
                    description={'до 5 млн. финансирование участия в соответствии с 44-ФЗ, 223-ФЗ'}
                    onClick={handleItemClick(ProductType.TENDER_LOAN)}
                    // color={tc.getColor('ORANGE', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_TENDER}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Оборот предприятия'}
                    description={'до 3 млн. финансирование под оборот расчетных счетов'}
                    onClick={handleItemClick(ProductType.TURNOVER_LOAN)}
                    // color={tc.getColor('MAGENTA', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_TURNOVER}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Услуги факторинга'}
                    description={'Сумма финансирования до 10 млн руб.'}
                    onClick={handleItemClick(ProductType.FACTORING_LOAN)}
                    // color={tc.getColor('MAGENTA', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_FACTORING}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Лизинг транспорта и техники'}
                    description={'Сумма финансирования до 5 млн руб. на автомашины и строительную технику'}
                    onClick={handleItemClick(ProductType.TRANSPORT_LEASING)}
                    // color={tc.getColor('MAGENTA', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_TRANSPORT}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Лизинг оборудования'}
                    description={'Лизинг на покупку нового оборудования до 5 млн руб.'}
                    onClick={handleItemClick(ProductType.EQUIPMENT_LEASING)}
                    // color={tc.getColor('MAGENTA', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_EQUIPMENT}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Заем под ПТС'}
                    description={'Сумма до 2 млн. рублей, срок от 6 до 24 месяцев и ставка от 2,5% в месяц!'}
                    onClick={handleItemClick(ProductType.LOAN_UNDER_PTS)}
                    // color={tc.getColor('MAGENTA', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_PTS}
                            width={96}
                            height={96}
                        />
                    )}
                />
                <ItemGridElement
                    title={'Заем на франшизу'}
                    description={'Открывай свой бизнес с Папой Финанс! Сумма финансирования до 3 млн руб.'}
                    onClick={handleItemClick(ProductType.FRANCHISE_LOAN)}
                    // color={tc.getColor('MAGENTA', { step: 4, darkMode })}
                    icon={(
                        <img
                            src={PapaImage.PRODUCT_FRANCHISE}
                            width={96}
                            height={96}
                        />
                    )}
                />
            </ItemGrid>
            <Space h={'l'}/>
        </>
    );
}
