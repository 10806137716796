import React, { useState } from 'react';
import { Button, Space } from 'just-core-ui';
import { ContainerPage, FooterEmpty, ModalHeader, Money, RadioGroup, Wrapper } from '~/components';
import { SessionSelectors } from '~/common/session';
import { Loan } from '~/common/product';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { PapaImage } from '~/common/papa-image';

const productMakePayment = (params) => Promise.resolve();

export type ProductPayProps = {
    loan: Loan;
    totalPayment: boolean;
};

const list = [
    {
        key: 'MAIL',
        title: 'Платеж с расчетного счета',
        description: 'Мы пришлем вам платежку на досрочное погашения в чат или на электронную почту',
    },
    {
        key: 'CARD',
        title: 'Корпоративной картой (скоро!)',
        description: 'Мы перенаправим вас на партнера Robokassa, а потом вернем обратно',
        disabled: true,
    },
];

export function ProductPay(props: ProductPayProps): JSX.Element {
    const jpmActions = useJustPageManagerActions();

    const popLayer = () => jpmActions.popLayer();
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [paymentType, setPaymentType] = useState(null);

    const user = SessionSelectors.user();
    const { loan } = props;
    const amount = props.totalPayment
        ? (loan.currentTotalAmount)
        : '999999.99'

    const makePayment = () => {
        setLoading(true);
        productMakePayment({
            productId: loan.key,
            type: props.totalPayment ? 'total' : 'partial',
        })
            .then(() => setCompleted(true))
            .catch(() => console.log('ERROR making payment'));
    };

    return (
        <ContainerPage>
            <Wrapper>
                {completed ? (
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src={PapaImage.MAILBOX}
                                width={110}
                                height={110}
                            />
                        </div>
                        <Space h={'l'}/>
                        <ModalHeader
                            title={(
                                <>
                                    Готово!
                                </>
                            )}
                            description={(
                                <>
                                    Счет фактура для оплаты ежемесячного платежа
                                    {' '}
                                    на <Money amount={amount}/>
                                    {' '}
                                    отправлена на почту {user.email}
                                </>
                            )}
                        />
                        <Space h={'3xl'}/>
                        <Button
                            onClick={popLayer}
                            size={'l'}
                            autoFill
                        >
                            Закрыть
                        </Button>
                    </>
                ) : (
                    <>
                        <ModalHeader
                            title={(
                                <>
                                    Внести
                                    {' '}
                                    <Money amount={amount}/>
                                    {' '}
                                    для
                                    {' '}
                                    {props.totalPayment ? 'досрочного погашения займа' : 'ежемесячного платежа'}
                                </>
                            )}
                            description={(
                                <>
                                    Выберите удобный способ для внесения платежа
                                </>
                            )}
                        />
                        <RadioGroup
                            currentKey={paymentType}
                            setCurrentKey={setPaymentType}
                            list={list}
                        />
                        <Space h={'3xl'}/>
                        <Button
                            onClick={makePayment}
                            size={'l'}
                            disabled={!paymentType || loading}
                            autoFill
                        >
                            Дальше
                        </Button>
                    </>
                )}
            </Wrapper>
            <FooterEmpty/>
        </ContainerPage>
    );
}
