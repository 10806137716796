import React from 'react';
import { DocTextSvg20 } from '~/common/icons';
import { Money, Wrapper } from '~/components';
import { formatDate } from '~/utils';
import { Loan } from '../common';
import { ProductCard } from './product-card';
import { ProductCardRow } from './product-card-row';

type ProductCardAboutProps = {
    data: Loan;
    onClick?: () => void;
};

export function ProductCardAbout(props: ProductCardAboutProps): JSX.Element {
    const { data } = props;

    return (
        <Wrapper>
            <ProductCard
                title={'О займе'}
                icon={DocTextSvg20}
                onClick={props.onClick}
            >
                <ProductCardRow
                    title={'Сумма займа:'}
                    value={<Money amount={data.initLoanAmount}/>}
                />
                {data.currentLoanAmount && (
                    <ProductCardRow
                        title={'Остаток долга:'}
                        value={<Money amount={data.currentLoanAmount}/>}
                    />
                )}
                {!!Number(data.currentCommissionAmount) && (
                    <ProductCardRow
                        title={'Сумма залога:'}
                        value={<Money amount={data.currentCommissionAmount}/>}
                    />
                )}
                {data.rate && (
                    <ProductCardRow
                        title={'Ставка:'}
                        value={`${data.rate.toFixed(2)} %`}
                    />
                )}
                <ProductCardRow
                    title={'Дата закрытия:'}
                    value={formatDate(data.initEndDate)}
                />
            </ProductCard>
        </Wrapper>
    );
}
