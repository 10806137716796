import React from 'react';
import { createUseStyles } from 'react-jss';
import { SpaceCode } from 'just-core-ui/common';
import { StyleService, Theme, useTheme } from 'just-core-ui';

export type WrapperProps = {
    space?: SpaceCode;
    children?: React.ReactNode;
};

const useStyles = createUseStyles({
    Wrapper: (props: WrapperProps & { theme: Theme }) => ({
        padding: `0 ${StyleService.instance.getSpace(props.theme, props.space)}px`,
    }),
});

export function Wrapper(props: WrapperProps): JSX.Element {
    const theme = useTheme();

    const { space = 's' } = props;

    const styles = useStyles({
        ...props,
        space,
        theme,
    });

    return (
        <div className={styles.Wrapper}>
            {props.children}
        </div>
    );
}
