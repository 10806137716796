import { JsonRpcClient } from '~/common/just-json-rpc-client';
import {
    SessionAuthTokenCheckParams,
    SessionAuthTokenCheckResult,
    SessionAuthTokenCreateParams,
    SessionAuthTokenCreateResult,
    SessionCheckParams,
    SessionCheckResult, SessionInnRequestCreateParams, SessionInnRequestCreateResult,
} from '~/common/app-api';

export class SessionRepository {
    static readonly instance = new SessionRepository();

    private jsonRpcClient: JsonRpcClient;

    constructor() {
        this.jsonRpcClient = new JsonRpcClient('/api', {
            addMethodToUrl: true,
        });
    }

    check(params: SessionCheckParams): Promise<SessionCheckResult> {
        return this.jsonRpcClient.call('session-check', params);
    }

    createAuthToken(params: SessionAuthTokenCreateParams): Promise<SessionAuthTokenCreateResult> {
        return this.jsonRpcClient.call('session-auth-token-create', params);
    }

    checkAuthToken(params: SessionAuthTokenCheckParams): Promise<SessionAuthTokenCheckResult> {
        return this.jsonRpcClient.call('session-auth-token-check', params);
    }

    createInnRequest(params: SessionInnRequestCreateParams): Promise<SessionInnRequestCreateResult> {
        return this.jsonRpcClient.call('session-inn-request-create', params);
    }
}
