import React from 'react';
import { UserHeader } from '~/components';
import { SessionSelectors } from '~/common/session';

export function HomeUserHeader(): JSX.Element {
    const customer = SessionSelectors.customer();

    return (
        <UserHeader
            name={customer.name}
        />
    );
}
