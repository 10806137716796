import React from 'react';
import { Space } from 'just-core-ui';
import { Wrapper } from '~/components';

export type ProfileRowClusterProps = {
    children: React.ReactNode;
};

export function ProfileRowCluster(props: ProfileRowClusterProps): JSX.Element {
    return (
        <Wrapper>
            <Space h={'xs'}/>
            {props.children}
            <Space h={'xs'}/>
        </Wrapper>
    );
}
