export enum ProductType {
    ACQUIRING_PREPAYMENT,
    MARKETPLACE_LOAN,
    ONLINE_STORE_LOAN,
    TENDER_LOAN,
    CONTRACT_LOAN,
    CARGO_LOAN,
    TURNOVER_LOAN,
    FACTORING_LOAN,
    TRANSPORT_LEASING,
    EQUIPMENT_LEASING,
    LOAN_UNDER_PTS,
    FRANCHISE_LOAN,
}
