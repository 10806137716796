import React from 'react';
import { createUseStyles } from 'react-jss';
import { StyleService, Text, Theme, useTheme } from 'just-core-ui';
import { Wrapper } from '~/components';

type HeadingProps = {
    children?: React.ReactNode;
};

const useStyles = createUseStyles({
    Heading: (theme: Theme) => ({
        padding: `${StyleService.instance.getSpace(theme, 'xl')}px 0 ${StyleService.instance.getSpace(theme, 'm')}px`,
    }),
});

export function Heading(props: HeadingProps): JSX.Element {
    const theme = useTheme();

    const styles = useStyles(theme);

    return (
        <Wrapper>
            <div className={styles.Heading}>
                <Text size={'l'} weight={'semi-bold'} height={'s'}>
                    {props.children}
                </Text>
            </div>
        </Wrapper>
    );
}
