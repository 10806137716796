import { LoanRequestJson } from '~/common/app-api';
import { LoanService, PaymentService, ScheduledPaymentService } from '../services';
import { productSlice } from './product-slice';

export const ProductActions = {
    getList: (customerId: number) => async (dispatch) => {
        try {
            dispatch(productSlice.actions.getListStart());

            const loans = await LoanService.instance.getLoans(customerId);

            dispatch(productSlice.actions.getListSuccess(loans));
        } catch (e) {
            dispatch(productSlice.actions.getListError());

            console.error('ProductActions.getList ERROR', e);
        }
    },

    getPaymentList: (customerId: number, loanKey: string) => async (dispatch) => {
        try {
            const payments = await PaymentService.instance.getPayments(customerId, loanKey);

            dispatch(productSlice.actions.getPaymentListSuccess({ loanKey, payments }));
        } catch (e) {
            console.error('ProductActions.getPaymentList ERROR', e);
        }
    },

    getScheduledPaymentList: (customerId: number, loanKey: string) => async (dispatch) => {
        try {
            const scheduledPayments = await ScheduledPaymentService.instance.getScheduledPayments(customerId, loanKey);

            dispatch(productSlice.actions.getScheduledPaymentListSuccess({ loanKey, scheduledPayments }));
        } catch (e) {
            console.error('ProductActions.getScheduledPaymentList ERROR', e);
        }
    },

    getLoanRequestsList: (customerId: number) => async (dispatch) => {
        try {
            const loanRequests = await LoanService.instance.getLoanRequests(customerId);

            loanRequests.reverse();

            dispatch(productSlice.actions.updateLoanRequests(loanRequests));
        } catch (e) {
            console.error('ProductActions.getLoanRequestsList ERROR', e);
        }
    },

    createLoanRequest: (customerId: number, type: string, amount: number, callback?: (loanRequest: LoanRequestJson) => void) => async dispatch => {
        try {
            const loanRequest = await LoanService.instance.createLoanRequest(customerId, type, amount);

            dispatch(productSlice.actions.addLoanRequests([loanRequest]));

            if (callback) {
                callback(loanRequest);
            }
        } catch (e) {
            console.error('ProductActions.createLoanRequest ERROR', e);
        }
    },
};
