import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer, User } from '../common';
import { SessionState } from './session-state';

const initialState: SessionState = {
    user: null,
    customer: null,
    checking: true,

    authToken: null,
    authTokenCreatedAt: null,
    authTokenLoading: false,

    authKeyCount: 0,
    authKeyLoading: false,

    innRequestCount: 0,
    innRequestLoading: false,
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        // CHECK

        checkStart: (state: SessionState) => {
            state.checking = true;
        },
        checkSuccess: (state: SessionState, action: PayloadAction<User>) => {
            state.checking = false;
            state.user = action.payload;

            if (state.user && state.user.customers.length === 1) {
                state.customer = state.user.customers[0];
            }
        },
        checkError: (state: SessionState) => {
            state.checking = false;
        },

        // CREATE AUTH TOKEN

        createAuthTokenStart: (state: SessionState) => {
            state.authTokenLoading = true;
        },
        createAuthTokenSuccess: (state: SessionState, action: PayloadAction<string>) => {
            state.authTokenLoading = false;
            state.authTokenCreatedAt = new Date();
            state.authToken = action.payload;
        },
        createAuthTokenError: (state: SessionState) => {
            state.authTokenLoading = false;
        },

        // CHECK AUTH TOKEN

        checkAuthTokenStart: (state: SessionState) => {
            state.authKeyLoading = true;
        },
        checkAuthTokenSuccess: (state: SessionState) => {
            state.authKeyLoading = false;
            state.authKeyCount++;
        },
        checkAuthTokenError: (state: SessionState) => {
            state.authKeyLoading = false;
        },

        // CREATE INN REQUEST

        createInnRequestStart: (state: SessionState) => {
            state.innRequestLoading = true;
        },
        createInnRequestSuccess: (state: SessionState) => {
            state.innRequestLoading = false;
            state.innRequestCount++;
        },
        createInnRequestError: (state: SessionState) => {
            state.innRequestLoading = false;
        },

        // SELECT CUSTOMER

        selectCustomer: (state: SessionState, action: PayloadAction<Customer>) => {
            state.customer = action.payload;
        },
    },
});
