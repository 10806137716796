import React from 'react';
import { createUseStyles } from 'react-jss';
import { useJustPageManagerActions } from '~/common/just-page-manager';
import { BackSvg24, CloseSvg24 } from '~/common/icons';
import { StyleService, Theme, useTheme } from 'just-core-ui';

const HEADER_HEIGHT = 40;

export type JpmLayoutBaseProps = {
    pages: Array<any>;
    render: (page: any) => React.ReactNode;
    hidePopLayerButton?: boolean;
};

const useStyles = createUseStyles({
    JpmLayoutBase: (theme: Theme) => ({
        backgroundColor: StyleService.instance.getTopColor(theme),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }),
    JpmLayoutBase__Head: (theme: Theme) => ({
        display: 'none',
        backgroundColor: StyleService.instance.getTopColor(theme),
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: HEADER_HEIGHT,
    }),
    JpmLayoutBase__Head_Left: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
    },
    JpmLayoutBase__Head_Right: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
    },
    JpmLayoutBase__Body: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    JpmLayoutBase__Body_Space: {
        // height: HEADER_HEIGHT,
    },
    JpmLayoutBase__Body_Content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        overflowY: 'auto',
        display: 'none',
        '&:last-child': {
            display: 'block',
        },
    },
    JpmLayoutBase__Button: {
        width: HEADER_HEIGHT,
        height: HEADER_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
});

export function JpmLayoutBase(props: JpmLayoutBaseProps): JSX.Element {
    const theme = useTheme();

    const actions = useJustPageManagerActions();

    const styles = useStyles(theme);

    return (
        <div className={styles.JpmLayoutBase}>
            <div className={styles.JpmLayoutBase__Body}>
                {props.pages.map((page, index) => (
                    <div className={styles.JpmLayoutBase__Body_Content} key={index}>
                        <div className={styles.JpmLayoutBase__Body_Space}/>
                        {props.render(page)}
                    </div>
                ))}
            </div>
            <div className={styles.JpmLayoutBase__Head}>
                <div className={styles.JpmLayoutBase__Head_Left}>
                    {props.pages.length > 1 && (
                        <div
                            className={styles.JpmLayoutBase__Button}
                            onClick={actions.popPage}
                        >
                            <BackSvg24 color={StyleService.instance.getBotColor(theme)}/>
                        </div>
                    )}
                </div>
                <div className={styles.JpmLayoutBase__Head_Right}>
                    {!props.hidePopLayerButton && (
                        <div
                            className={styles.JpmLayoutBase__Button}
                            onClick={actions.popLayer}
                        >
                            <CloseSvg24 color={StyleService.instance.getBotColor(theme)}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
